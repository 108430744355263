import React from "react";
import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/layout";
import Layout from "layout";
import webDev from "assets/images/webDev.jpg";
import webCode from "assets/images/webcode.jpg";
import webPersons from "assets/images/webPersons.jpg";
import techStack from "assets/images/techStack.png";
import { Image } from "@chakra-ui/image";
import { EndlessPossibilities, ShareIcon } from "assets/icons";
import { Button } from "@chakra-ui/button";
import { intoView } from "utils/hekper";

export default function WebDevelopment() {
  return (
    <Layout>
      <>
        <Box
          bg={`url(${webDev}) no-repeat center`}
          bgSize="100% 100%"
          h="512px"
          textAlign="center"
        >
          <Flex
            flexDir="column"
            gap="15px"
            justify="center"
            align="center"
            maxH="512px"
            h="100%"
          >
            <Heading fontSize="58px" fontWeight="900" color="white">
              Web Development{" "}
            </Heading>
            <Text
              fontSize="18px"
              fontWeight="400"
              color="white"
              maxW="722px"
              w="100%"
            >
              Our web development services can help businesses establish an
              online presence, improve their website&apos;s functionality, and
              create custom web solutions that meet their unique needs.
            </Text>
          </Flex>
        </Box>
        <Container maxW="1280px">
          <Flex my="62px" gap="44px" flexDir={{ base: "column", md: "row" }}>
            <Box flex={0.5}>
              <Heading fontSize="50px" fontWeight={800}>
                We&apos;re on a mission to bring ideas to life.
              </Heading>
              <Text fontSize="20px" fontWeight="400" mt="31px">
                From simple apps to complex ecommerce portals, our team offers
                custom web development services that match your strategic goals.
              </Text>
              <Divider my="40px" />
              <Flex gap="22px">
                <Center bg="#F6F6F6" boxSize="100px" borderRadius="20px">
                  <ShareIcon />
                </Center>
                <Box>
                  <Heading fontWeight="700" fontSize="30px">
                    How technology started
                  </Heading>
                  <Text fontSize="20px" fontWeight="400">
                    Building an enterprise doesn&apos;t need
                    <br /> your thousands.
                  </Text>
                </Box>
              </Flex>
              <Flex gap="22px" mt="27px">
                <Center bg="#F6F6F6" boxSize="100px" borderRadius="20px">
                  <EndlessPossibilities />
                </Center>
                <Box>
                  <Heading fontWeight="700" fontSize="30px">
                    Endless Possibilities
                  </Heading>
                  <Text fontSize="20px" fontWeight="400">
                    Unlocking potential with tailored <br /> solutions to meet
                    goals.
                  </Text>
                </Box>
              </Flex>
              <Button
                mt="40px"
                bg="#2F2F2F"
                rounded="full"
                w={{ base: "100%", md: "257px" }}
                h="80px"
                fontSize="20px"
                fontWeight={600}
                onClick={() => intoView("contact")}
              >
                Contact Us Now
              </Button>
            </Box>
            <Box flex={0.5} display={{ base: "none", md: "block" }}>
              <Flex flexWrap="wrap" gap="14px">
                <Image src={webCode} flex={1} borderRadius="20px" />
                <Box bg="#5956E9" borderRadius="20px" flex={0.4} py="3rem">
                  <Box textAlign="center">
                    <Heading fontSize="70px" fontWeight={800} color="white">
                      20+
                    </Heading>
                    <Text fontSize="20px" fontWeight={700} color="white">
                      Active Projects
                    </Text>
                  </Box>
                </Box>
                <Image
                  borderRadius="20px"
                  src={webPersons}
                  h="214px"
                  flex={0.6}
                />
              </Flex>
            </Box>
          </Flex>
          <Flex
            gap="40px"
            align="center"
            mb="50px"
            flexDir={{ base: "column", md: "row" }}
          >
            <Box flex={0.5}>
              <Image src={techStack} />
            </Box>
            <Box flex={0.5}>
              <Heading
                fontSize="50px"
                textAlign={{ base: "center", md: "unset" }}
                fontWeight={800}
              >
                Technology Stack
              </Heading>
              <Text fontSize="20px" fontWeight="400" mt="31px">
                Our web app development services comprise enterprise web apps,
                customer-facing apps, and analytics applications.
              </Text>
              <UnorderedList mt="1rem" fontSize="20px" fontWeight="400">
                <ListItem>Customer service apps</ListItem>
                <ListItem>Project management & collaboration tools</ListItem>
                <ListItem>Financial management systems</ListItem>
                <ListItem>Online banking & payment solutions</ListItem>
              </UnorderedList>
            </Box>
          </Flex>
        </Container>
      </>
    </Layout>
  );
}
