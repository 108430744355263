import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Tag,
  Text,
} from "@chakra-ui/react";
import Layout from "layout";
import blogHeader from "assets/images/blogHeader.jpg";
import chess from "assets/images/chess.jpg";
import { MdArrowOutward } from "react-icons/md";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
export default function Blog() {
  const [activePage, setActivePage] = React.useState(2);
  return (
    <Layout>
      <Box
        bg={`url(${blogHeader}) no-repeat center`}
        bgSize="100% 100%"
        py="96px"
        textAlign="center"
      >
        <Text color="white">Our blog</Text>
        <Heading
          color="white"
          fontSize="40px"
          fontWeight="700"
          mt="12px"
          mb="24px"
        >
          Stories and blog posts
        </Heading>
        <Text color="white" mb="40px" fontSize="20px">
          Subscribe to learn about new product features, the latest in
          technology, solutions, and updates.
        </Text>
        <HStack gap="1rem" justify="center">
          <Input
            size="lg"
            maxW="360px"
            w="100%"
            placeholder="Enter your email"
            bg="white"
          />
          <Button size="lg">Subscribe</Button>
        </HStack>
        <Text color="white" mt="8px">
          We care about your data in our privacy policy
        </Text>
      </Box>
      <Container maxW="1280px" my="100px">
        <Heading fontSize="24px" fontWeight="400" mb="2rem">
          Recent blog posts
        </Heading>
        <Flex gap="32px" mb="0.8rem" flexDir={{ base: "column", lg: "row" }}>
          <Box flex={0.5}>
            <Image src={chess} w="100%" h="243px" objectFit="cover" />
            <Text
              fontSize="14px"
              fontWeight="600"
              bgGradient="linear(to-l, #22A7FA, #4026E2)"
              bgClip="text"
              mt="32px"
              mb="12px"
            >
              Alec Whitten • 17 Jan 2022
            </Text>
            <HStack justify="space-between">
              <Heading fontSize="24px" fontWeight="600">
                Bill Walsh leadership lessons
              </Heading>
              <MdArrowOutward />
            </HStack>
            <Text color="#667085" mt="12px">
              Like to know the secrets of transforming a 2-14 team into a 3x
              Super Bowl winning Dynasty?
            </Text>
            <HStack spacing={2} mt="24px">
              <Tag size="sm" variant="subtle" colorScheme="cyan">
                Leadership
              </Tag>
              <Tag size="sm" variant="subtle" colorScheme="purple">
                Management
              </Tag>
              <Tag size="sm" variant="subtle" colorScheme="red">
                Frameworks
              </Tag>
            </HStack>
          </Box>
          <Box flex={0.5}>
            {[1, 2].map((item) => (
              <Flex
                gap="24px"
                key={item}
                mb="47px"
                flexDir={{ base: "column", md: "row" }}
              >
                <Image
                  src={chess}
                  objectFit="cover"
                  maxW={{ base: "100%", md: "320px" }}
                  h="200px"
                  w="100%"
                />
                <Box>
                  <Text
                    fontSize="14px"
                    fontWeight="600"
                    bgGradient="linear(to-l, #22A7FA, #4026E2)"
                    bgClip="text"
                    mb="12px"
                  >
                    Alec Whitten • 17 Jan 2022
                  </Text>

                  <Heading fontSize="18px" fontWeight="600">
                    Unlocking the Potential of Blockchain
                  </Heading>

                  <Text color="#667085" mt="12px">
                    Like to know the secrets of transforming a 2-14 team into a
                    3x Super Bowl winning Dynasty?
                  </Text>
                  <HStack spacing={2} mt="16px">
                    <Tag size="sm" variant="subtle" colorScheme="cyan">
                      Leadership
                    </Tag>
                    <Tag size="sm" variant="subtle" colorScheme="purple">
                      Management
                    </Tag>
                    <Tag size="sm" variant="subtle" colorScheme="red">
                      Frameworks
                    </Tag>
                  </HStack>
                </Box>
              </Flex>
            ))}
          </Box>
        </Flex>
        <Heading fontSize="24px" fontWeight="400" mb="2rem">
          All blog posts
        </Heading>
        <Flex flexWrap="wrap" gap="32px" justify="center">
          {[1, 2, 3, 6, 7, 8, 9].map((blog) => (
            <Box
              minW={{ base: "100%", md: "384px" }}
              maxW={{ base: "100%", md: "384px" }}
              key={blog}
            >
              <Image
                src={chess}
                maxW={{ base: "100%", md: "384px" }}
                w="100%"
              />
              <Text
                fontSize="14px"
                fontWeight="600"
                bgGradient="linear(to-l, #22A7FA, #4026E2)"
                bgClip="text"
                mt="32px"
                mb="12px"
              >
                Alec Whitten • 17 Jan 2022
              </Text>
              <HStack justify="space-between">
                <Heading fontSize="24px" fontWeight="600">
                  Bill Walsh leadership lessons
                </Heading>
                <MdArrowOutward />
              </HStack>
              <Text color="#667085" mt="12px">
                Like to know the secrets of transforming a 2-14 team into a 3x
                Super Bowl winning Dynasty?
              </Text>
              <HStack spacing={2} mt="24px">
                <Tag size="sm" variant="subtle" colorScheme="cyan">
                  Leadership
                </Tag>
                <Tag size="sm" variant="subtle" colorScheme="purple">
                  Management
                </Tag>
                <Tag size="sm" variant="subtle" colorScheme="red">
                  Frameworks
                </Tag>
              </HStack>
            </Box>
          ))}
        </Flex>
        <Divider my="2rem" />
        <HStack justify="space-between">
          <Button leftIcon={<ArrowBackIcon />} variant="ghost">
            Previous
          </Button>
          <Box>
            {[1, 2, 3].map((no) => (
              <IconButton
                key={no}
                aria-label="btn"
                bg={activePage === no ? "#DEF2FE" : "transparent"}
                icon={
                  <Text color="#4026E2" fontSize="14px">
                    {no}
                  </Text>
                }
                onClick={() => setActivePage(no)}
              />
            ))}
          </Box>
          <Button rightIcon={<ArrowForwardIcon />} variant="ghost">
            Next
          </Button>
        </HStack>
      </Container>
    </Layout>
  );
}
