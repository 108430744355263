import React from "react";
import Layout from "layout";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Container,
  Image,
} from "@chakra-ui/react";
import ProcessBg from "assets/images/process-bg.jpg";
import DynamicUI from "assets/images/dynamic-ui.png";
import BubblesBg from "assets/images/bubbles-bg.png";
import PROCESS_DATA from "utils/data/process.data";
import DESIGN_DATA from "utils/data/design.data";
import { intoView } from "utils/hekper";
import uiux from "assets/images/uiux-image.jpg";

export default function DesignService() {
  return (
    <Layout>
      <Box
        bg={`url(${uiux}) no-repeat center`}
        bgSize="100% 100%"
        py="96px"
        textAlign="center"
      >
        <Flex
          flexDir="column"
          gap="15px"
          justify="center"
          align="center"
          maxH="512px"
          h="100%"
        >
          <Heading
            fontSize={{ base: "25px", md: "58px" }}
            fontWeight="900"
            color="white"
          >
            UX/UI Design Services
          </Heading>
          <Text
            fontSize={{ base: "14px", md: "18px" }}
            fontWeight="400"
            color="white"
            maxW="722px"
            w="100%"
            px={{ base: "10px", md: "0px" }}
          >
            We are specialized in creating beautiful and smooth UI/UX designs
            that provide better user experience by incorporating effective
            collaboration, streamlined projects which strive for better results.
          </Text>
          <Button mt="35px" onClick={() => intoView("contact")}>
            TALK TO US
          </Button>
        </Flex>
      </Box>
      <Box my="60px" as="section">
        <Container maxW="1280px">
          <Flex
            gap="30px"
            flexDir={{ base: "column", md: "row" }}
            justify="center"
          >
            <Box
              maxW="500px"
              bg={`url(${ProcessBg}) no-repeat bottom`}
              bgSize="cover"
              w="100%"
              p="85px 45px"
              rounded="18px"
              mx="auto"
            >
              <Heading
                fontSize={{ base: "25px", md: "50px" }}
                as="h2"
                color="white"
              >
                Our Working Process - How We Work For Our Customers
              </Heading>
              <Text
                mt="22px"
                fontSize={{ base: "14px", md: "20px" }}
                variant="primary"
              >
                We collaborate closely with our customers to understand their
                requirements and develop designs.
              </Text>
              {/* <Button
                variant="primary"
                mt="40px"
                size={{ base: "md", md: "lg" }}
                fontSize={{ base: "14px", md: "20px" }}
                rounded="50px"
                onClick={() => intoView("contact")}
              >
                Get Started
              </Button> */}
            </Box>
            <Flex wrap="wrap" gap="30px" justify="center">
              {PROCESS_DATA?.map(({ num, title, disc }) => (
                <Box key={num} maxW="340px" w="100%" minW="180px">
                  <Text
                    fontWeight="900"
                    variant="secondary"
                    fontSize={{ base: "35px", md: "50px" }}
                  >
                    {num}
                  </Text>
                  <Heading
                    as="h4"
                    mt="14px"
                    fontSize={{ base: "25px", md: "30px" }}
                  >
                    {title}
                  </Heading>
                  <Text mt="12px" fontSize={{ base: "14px", md: "16px" }}>
                    {disc}
                  </Text>
                </Box>
              ))}
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Box as="section">
        <Image src={DynamicUI} mx="auto" mb="-180px" w="100%" />
        <Container maxW="1280px">
          <Box bg="primaryGradient" rounded="8px">
            <Box
              bg={`url(${BubblesBg}) no-repeat bottom`}
              bgSize="contain"
              pt="170px"
              pb={{ base: "50px", md: "135px" }}
              textAlign="center"
              px={{ base: "8px", md: "0px" }}
            >
              <Heading
                as="h3"
                color="white"
                fontSize={{ base: "18px", md: "25px" }}
                maxW="770px"
                mx="auto"
              >
                We Produce Dynamic UI UX Designs on the Web to Enhance your
                Brand Value and Turn Creative Ideas into User-Friendly
                Experiences
              </Heading>
              <Text
                variant="primary"
                maxW="655px"
                mx="auto"
                mt="24px"
                fontSize={{ base: "14px", md: "16px" }}
              >
                We are a leading and trusted web UI/UX design services provider
                with expertise in innovative and seamless customer experiences
                that are both delightful and functional. Our UI UX designers
                work as your extended team to understand your business and
                operating requirements and deliver a prototype that forms the
                base for your new web solution.
              </Text>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box bg="lightGray" my="100px" py="50px">
        <Heading as="h2" textAlign="center">
          Our UI/UX Design Services
        </Heading>
        <Container maxW="1280px" mt="60px">
          <Flex gap="30px" wrap="wrap" justify="center">
            {DESIGN_DATA?.map(({ Icon, title, disc }) => (
              <Box key={title} maxW="440px">
                <Icon />
                <Heading
                  my="20px"
                  as="h4"
                  fontSize={{ base: "16px", md: "20px" }}
                >
                  {title}
                </Heading>
                <Text fontSize={{ base: "14px", md: "16px" }}>{disc}</Text>
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>
    </Layout>
  );
}
