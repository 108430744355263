import React from "react";
import {
  Box,
  Button,
  Card,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import {
  Deployment,
  Design,
  Development,
  HeadingImage,
  Maintance,
  Requirements,
  Testing,
} from "assets/icons";
import Layout from "layout";
import aboutbg from "assets/images/aboutbg.png";
import teamwork from "assets/images/teamWork.jpg";
import lifeAtBlockyfy from "assets/images/lifeAtblockyfy.jpg";
import { ConsultaionPhase } from "assets/icons";

import ExperienceMemberProjectCard from "components/portfolioUtils";
import ContactUsCard from "components/contactCard";
import roadmap from "assets/images/roadmap.png";
import RoadMapCard from "components/roadMapCard";
import howDoWeDoData from "utils/data/howDoWeDo.data";
import Banner from "components/banner";
export default function HowDoWeDo() {
  return (
    <Layout>
      <Banner title="Get to know how we operate" name="How do we do" />

      <Box
        bg={{ md: ` url(${aboutbg}) left center no-repeat` }}
        bgSize={{ md: "contain" }}
        my={{ base: "100px", md: "160px" }}
      >
        <Container maxW="1280px">
          <Flex
            gap={{ base: "12px", md: "118px" }}
            flexDir={{ base: "column", md: "row" }}
          >
            <Box
              flex={0.4}
              bg={` url(${teamwork}) no-repeat`}
              bgSize="100% 100%"
              pos="relative"
              display={{ base: "none", md: "unset" }}
            >
              <Flex
                flexDir="column"
                gap="15px"
                pos="absolute"
                right="-8"
                top="70px"
                display={{ base: "none", md: "unset" }}
              >
                <Image
                  src={lifeAtBlockyfy}
                  boxSize="105px"
                  borderRadius="4px"
                  boxShadow="xs"
                />
                <Image
                  src={teamwork}
                  boxSize="105px"
                  borderRadius="4px"
                  boxShadow="xs"
                />

                <Image
                  src={lifeAtBlockyfy}
                  boxSize="105px"
                  borderRadius="4px"
                  boxShadow="xs"
                />
              </Flex>
            </Box>
            <Box
              flex={0.6}
              py={{ md: "65px" }}
              textAlign={{ base: "center", md: "unset" }}
            >
              <HStack spacing="5px" justify={{ base: "center", md: "unset" }}>
                <HeadingImage />
                <Heading
                  fontSize="13px"
                  fontWeight={700}
                  textTransform="uppercase"
                >
                  Working Process
                </Heading>
              </HStack>
              <Heading
                fontSize="40px"
                fontWeight={700}
                my="22px"
                textTransform="uppercase"
              >
                We complete every step carefully
              </Heading>
              <Text my="1rem">
                Our work methodology is essential in shaping the way we operate.
                By understanding how we work, you can gain insights into our
                processes, principles, and approaches to achieving success.
                Discover the key aspects of our work method and gain a clear
                understanding of how we navigate projects, collaborate, and
                deliver results. Explore our unique approach and see how it
                aligns with your expectations and goals.
              </Text>

              <Button size="lg" mt="12px">
                Start Your Project
              </Button>
            </Box>
          </Flex>
        </Container>
      </Box>
      <ExperienceMemberProjectCard />
      <Box bg="#FBFBFB" pt="120px" pb="260px" mb="120px">
        <Container maxW="1280px">
          <Box textAlign="center" mb="4.8rem">
            <HStack spacing="5px" justify="center">
              <HeadingImage />
              <Heading
                fontSize="13px"
                fontWeight={700}
                textTransform="uppercase"
              >
                Our Services
              </Heading>
            </HStack>
            <Heading
              fontSize="40px"
              fontWeight={700}
              my="22px"
              textTransform="uppercase"
            >
              We provide different It Services
            </Heading>
            <Text my="1rem">
              Our work methodology is essential in shaping the way we operate.
              By understanding how we work, you can gain insights into our
              processes, principles, and approaches to achieving success.
              Discover the key aspects of our work method and gain a clear
              understanding of how we navigate projects, collaborate, and
              deliver results. Explore our unique approach and see how it aligns
              with your expectations and goals.
            </Text>
          </Box>
          <Box
            bg={` url(${roadmap}) no-repeat`}
            bgSize="100% 100%"
            h="780px"
            pos="relative"
            display={{ base: "none", md: "block" }}
          >
            <Grid
              h="648px"
              templateRows="repeat(3, 1fr)"
              templateColumns="repeat(3, 1fr)"
              gap={4}
            >
              <GridItem colSpan={1} mx="auto" mt="-10">
                <RoadMapCard
                  icon={<ConsultaionPhase />}
                  number={1}
                  heading="Consultation phase"
                  detail="At start we consult our client, gather & analyze data, establish communication protocols."
                />
              </GridItem>
              <GridItem colSpan={1} mx="auto" mt="-10">
                <RoadMapCard
                  icon={<Requirements />}
                  number={2}
                  heading="Requirements"
                  detail="Create a detailed project plan that outlines the project scope, milestones, deliverables, and resources required"
                />
              </GridItem>{" "}
              <GridItem colSpan={1} mx="auto" mt="-10">
                <RoadMapCard
                  icon={<Design />}
                  number={3}
                  heading="Design Phase"
                  detail="Develop wireframes, prototypes, and other design artifacts that align with the client&apso;s vision and expectations"
                />
              </GridItem>{" "}
              <GridItem colSpan={1} mx="auto" mt="8rem">
                <RoadMapCard
                  icon={<Development />}
                  number={4}
                  heading="Development Phase"
                  detail="Implement an agile development process that enables rapid iteration and feedback"
                />
              </GridItem>
              <GridItem colSpan={1} mx="auto" mt="8rem">
                <RoadMapCard
                  icon={<Testing />}
                  number={5}
                  heading="Testing Phase"
                  detail="Conduct rigorous testing and quality assurance processes to ensure the product functions as intended and meets quality standards"
                />
              </GridItem>{" "}
              <GridItem colSpan={1} mx="auto" mt="8rem">
                <RoadMapCard
                  icon={<Deployment />}
                  number={6}
                  heading="Deployment"
                  detail="Work with the client to ensure a successful deployment and launch of the product"
                />
              </GridItem>{" "}
              <GridItem colSpan={3} mx="auto" mt="7rem">
                <RoadMapCard
                  icon={<Maintance />}
                  number={7}
                  heading="Maintenance"
                  detail="Provide ongoing maintenance and support services to ensure the product continues to meet the client's needs and functions as intended"
                />
              </GridItem>
            </Grid>
          </Box>
          {howDoWeDoData?.map((data) => (
            <RoadMap {...data} key={data?.number} />
          ))}
          {/* <Image src={roadmap} /> */}
        </Container>
      </Box>
      <ContactUsCard />
    </Layout>
  );
}
interface IRoadMap {
  Icon: () => JSX.Element;
  heading: string;
  number: number;
  detail: string;
}
function RoadMap({ Icon, heading, number, detail }: IRoadMap) {
  return (
    <Card
      bg="#FBFBFB"
      textAlign="center"
      p="32px"
      mb="16px"
      boxShadow="xs"
      display={{ base: "block", md: "none" }}
    >
      <Center
        boxSize="72px"
        rounded="full"
        border="2px solid #22A7FA"
        mx="auto"
        mb="1rem"
      >
        <Icon />
      </Center>
      <Heading fontSize="20px" fontWeight={600}>
        {number}
      </Heading>
      <Heading my="8px" fontSize="20px" fontWeight={600}>
        {heading}
      </Heading>
      <Text>{detail}</Text>
    </Card>
  );
}
