import { IBlockchainDevelopment } from "utils/types/blockchain.type";
import {
  BlockChainApplication,
  BlockchainConsulting,
  BlockChainIntegration,
  Defi,
  DigitalCrypto,
  FinTech,
  HybirdApp,
  NativeApp,
  Lock,
  NFTMarket,
  PrivateBlockchain,
  ProgressiveWebApp,
  SmartContract,
  CryptoTransfer,
  TransactionHistory,
  Conversion,
  Biometric,
} from "assets/icons";
import rebranded from "assets/images/fully-rebranded.jpg";
import rapidLaunchDeployment from "assets/images/rapid-launch-deployment.jpg";
import infrastructure from "assets/images/forget-infrastructure.jpg";
import developSdk from "assets/images/develop-sdk.png";
import domain from "assets/images/domain.jpg";
import subtract from "assets/images/subtract.jpg";

export const blockChainDevelopment: IBlockchainDevelopment[] = [
  {
    icon: BlockchainConsulting(),
    title: "Blockchain Consulting",
    detail:
      "Our expertise lies in translating innovative ideas into market-ready solutions, refining digital strategies, mapping neat infrastructures, and boosting ROI.",
  },
  {
    icon: SmartContract(),
    title: "Smart Contract Development",
    detail:
      "Efficient smart contract development with secure coding in Solidity, Chaincode, or other platform-specific languages for streamlined and transparent business processes.",
  },
  {
    icon: BlockChainApplication(),
    title: "Blockchain Application Development",
    detail:
      "We build user-friendly DApps that leverage blockchain technology, specialize in private blockchain development for secure consortium networks.",
  },
  {
    icon: PrivateBlockchain(),
    title: "Private Blockchain Development",
    detail:
      "Specializing in private blockchain development for controlled networks, we establish secure consortium or permissioned networks to streamline operations and enhance trust.",
  },
  {
    icon: BlockChainIntegration(),
    title: "Blockchain Integration",
    detail:
      "Seamless integration of blockchain technology into existing systems for supply chain, identity verification, and data integrity, maximizing transformative benefits.",
  },
  {
    icon: NFTMarket(),
    title: "NFT Marketplace Development",
    detail:
      "Specializing in NFT marketplace development, we enable secure and transparent trading of unique digital assets using industry-standard protocols.",
  },
  {
    icon: FinTech(),
    title: "Fintech Development",
    detail:
      "We offer blockchain-based fintech development, including secure identity verification, cross-border payments, decentralized lending, and compliance with regulatory frameworks.",
  },
  {
    icon: Defi(),
    title: "DeFi (Decentralized Finance) Solutions",
    detail:
      "Specializing in DeFi development, we create secure and transparent solutions for peer-to-peer lending, DEXs, yield farming, and more.",
  },
  {
    icon: DigitalCrypto(),
    title: "Digital Crypto Wallet Development",
    detail:
      "Cryptocurrency wallets safeguard and manage digital assets securely. We develop intuitive, secure wallets supporting various blockchains.",
  },
];

export const AppDevelopmentServices = [
  {
    image: NativeApp(),
    heading: "Native Mobile App Development",
    description:
      "Our mobile app developers can build high-quality native apps for both Android and iOS systems aligned with your business and security requirements.",
  },
  {
    image: HybirdApp(),
    heading: "Hybrid Mobile App Development",
    description:
      "Cross-platform apps that can work in different environments thanks to a unique blend of native and web app technologies.",
  },
  {
    image: ProgressiveWebApp(),
    heading: "Progressive Web App Development",
    description:
      "With Progressive Web Apps, we deliver native-like capabilities and insatiability while reaching anyone, anywhere, on any device with a single codebase.",
  },
];
export const WalletData = [
  {
    title: "Highly Scalable",
    detail:
      "Blockyfy digital wallet platform utilizes modular architecture which makes it easy to add new workflows, features and integrations.",
  },
  {
    title: "Customer-Centric Apps",
    detail:
      "We deliver mobile wallets with fast self-onboarding, extensive payment options, value-added services and clear design for frictionless user experience.",
  },
  {
    title: "End-to-end Delivery",
    detail:
      "Our clients receive ready-made wallet apps, customizations and design, training, ongoing support and maintenance.",
  },
  {
    title: "Cloud and On-Premises Deployment",
    detail:
      "Virtual wallets can be deployed on AWS Cloud Hosting aswell as on-premises data centers.",
  },
];

export const BrandingFeatures = [
  {
    image: rebranded,
    title: "Fully rebranded apps for all platforms",
    detail:
      "Your clients will be able to access your app on web,mobile, and tablets without worrying about software compatibility. Personalize your mobile applications and publish it to the App Store and Play Store.",
  },
  {
    image: rapidLaunchDeployment,

    title: "Rapid launch with one click deployment",
    detail:
      "You can push even the most complex changes into production and your app can go live across all devices using our built in one-click deployment capability.",
  },
  {
    image: infrastructure,

    title: "Forget infrastructure and focus on apps",
    detail:
      "Blockyfy is a ready-made platform where developers can build, test, and deploy on-demand business apps without worrying about complicated backend. You don’t have to buy and maintain servers, troubleshoot hardware, run software patches, or perform upgrades. Your apps still run on the servers that power Blockyfy.",
  },
  {
    image: developSdk,

    title: "Developer SDK to build custom functionality for your customers",
    detail:
      "In addition to the automatic generation of mobile apps, Blockyfy’s mobile SDK and UI kit allow users to build rebranded mobile apps while utilizing the platform’s architecture and security. And if you already have a full-fledged app that’s lacking some functionalities, Blockyfy allows you to build and attach the required module yourself.",
  },
  {
    image: domain,

    title: "Use Your Own Domain",
    detail:
      "Strengthen your brand by showcasing it in your form URLs. Connect your custom online forms to your custom domain, helping to improve your website’s search rankings. Design customer portals that behave like a natural extension of your company’s website. Host them on your own domain, to be accessed from your business website instead of Blockyfy’s.",
  },
  {
    image: subtract,

    title: "Strong authentication mechanisms",
    detail:
      "Perform centralized management of user authentication and access rights. Choose between two different authentication mechanisms—single sign-on (SSO) using a third-party identity provider, or federated logins (Google, Facebook)—or simply use Blockyfy’s own login.",
  },
];

export const SoftwareFeatures = [
  {
    icon: Lock(),
    heading: "Secure Wallet Setup:",
    detail:
      "Safely create or import a wallet secured by a private key, ensuring complete control and security over your digital assets.",
  },
  {
    icon: CryptoTransfer(),
    heading: "Crypto Transfers",
    detail:
      "Seamlessly send and receive cryptocurrencies, ensuring secure and convenient transactions in the ever-expanding digital currency ecosystem.",
  },
  {
    icon: TransactionHistory(),
    heading: "Transaction History",
    detail:
      "Easily access and review your past transactions providing a transparent and detailed history of financial activities.",
  },
  {
    icon: Conversion(),
    heading: "Crypto Conversion",
    detail:
      "Effortlessly swap cryptocurrencies allowing users to exchange digital assets with ease and convenience, all in one secure platform.",
  },
  {
    icon: Biometric(),
    heading: "Biometric Authentication",
    detail:
      "Enhance the security of digital wallet with biometric verification, allowing for an added layer of protection.",
  },
];

export const PaymentPlanType = [
  {
    type: "Basic",
    description: "Perfect edition for crypto beginners and startups",
    price: "From $10,000",
    properties: [
      "iOS / Android mobile app",
      "ETH20, TRON",
      "2 coins ( ETH, TRX) ",
      "2 tokens ( USDT, USDC )",
      "Send & Receive",
      "Swap",
      "Address Book",
      "Biometric",
    ],
  },
  {
    type: "Lite",
    description: "A more extended version for more complex needs & goals",
    price: "From $15,000",
    properties: [
      "iOS / Android mobile app",
      "ETH20,BSC, TRON",
      "3 coins ( ETH, BNB, TRX) ",
      "3 tokens ( USDT, USDC, BUSD )",
      "Send & Receive",
      "Swap",
      "Buy",
      "Address Book",
      "Import Token",
      "Biometric",
    ],
  },
  {
    type: "Medium",
    description:
      "Professional functionalities in addition to all standard features",
    price: "From $25,000",
    properties: [
      "iOS / Android mobile app",
      "ETH20, BSC, Polygon, TRON, Avalanche-C,Fantom ",
      "6 coins ( ETH, BNB, TRX, Matic, AVAX, FTM) ",
      "5 tokens ( USDT, USDC, BUSD, USDD )",
      "Send & Receive",
      "Swap",
      "Buy & Sell",
      "Address Book",
      "Import Token",
      "Custom Dark mode",
      "Biometric",
    ],
  },
  {
    type: "Pro",
    description: "Full suite solution with advanced features",
    price: "Per request",
    properties: [
      "iOS / Android mobile app",
      "ETH20, BSC, TRON, Polygon, Avalanche-C, Fantom",
      "6 coins ( ETH, TRX, BNB, Matic, AVAX, FTM) ",
      "5 tokens ( USDT, USDC, BUSD, USDD )",
      "Send & Receive",
      "Swap",
      "Buy & Sell",
      "Address Book",
      "Import Token",
      "Custom Dark mode",
      "Price Charts",
      "Customization as per requirement",
    ],
  },
];
