import React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import mask from "assets/images/bubbles-bg.png";
import { intoView } from "utils/hekper";

export default function ContactUsCard() {
  return (
    <Container
      maxW="1280px"
      my={{ base: "40px", md: "120px" }}
      borderRadius={{ base: "opx", md: "8px" }}
      bgGradient="linear(to-r, #22A7FA, #4026E2)"
      pos="relative"
    >
      <Image src={mask} pos="absolute" h="100%" w="100%" right="0" />
      <Flex
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: "24px", md: "160px" }}
        p={{ base: "65px 20px", md: "72px 132px" }}
        align="center"
      >
        <Box textAlign={{ base: "center", md: "unset" }}>
          <Heading
            fontSize="40px"
            fontWeight={700}
            color="white"
            mb="24px"
            textTransform="uppercase"
          >
            Need help to drive your business through our digital skills?
          </Heading>
          <Text color="white">
            We welcome new projects and are ready to assist you in bringing them
            to life. Whether you have a clear vision or are in the early stages,
            we offer expert guidance and services for project success.
          </Text>
        </Box>
        <Button
          w="170px"
          bg="white"
          _hover={{}}
          display={{ base: "block", md: "unset" }}
          mx={{ base: "auto", md: "unset" }}
          onClick={() => intoView("contact")}
          variant="primary"
          fontSize="12px"
        >
          <span>Contact Now</span>
        </Button>
      </Flex>
    </Container>
  );
}
