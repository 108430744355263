import { Card, Heading, Text } from "@chakra-ui/react";
import { DropShape } from "assets/icons";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  Icon: () => JSX.Element;
  disc: string;
  path: string;
}

export default function ServiceCard({ title, Icon, disc, path }: Props) {
  return (
    <Card
      boxShadow="none"
      bg="lightGray"
      gap="30px"
      pb="40px"
      px="30px"
      textAlign="center"
      align="center"
      maxW="382px"
      w="100%"
      border="1px solid #C5C5C5"
      as={Link}
      to={path}
    >
      <DropShape />
      <Icon />
      <Heading as="h3" fontSize="25px" textTransform="uppercase">
        {title}
      </Heading>
      <Text>{disc}</Text>
    </Card>
  );
}
