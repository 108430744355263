import React from "react";
import banner from "assets/images/banner-bg.jpg";
import { Center, Heading, Text } from "@chakra-ui/react";

interface Props {
  title: string;
  name: string;
}

export default function Banner({ title, name }: Props) {
  return (
    <Center
      bg={`url(${banner}) no-repeat center`}
      bgSize="cover"
      flexDirection="column"
      py="130px"
      textAlign="center"
    >
      <Heading color="white" textTransform="uppercase">
        {title}
      </Heading>
      <Text mt="40px" variant="primary">
        Home / {name}
      </Text>
    </Center>
  );
}
