import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import Layout from "layout";
import { HeadingImage } from "assets/icons";
import servicesBg from "assets/images/services-bg.png";
import ProjectCard from "components/projectCard";
import PROJECTS_DATA from "utils/data/projects.data";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Banner from "components/banner";
import { PROJECT_FILTER } from "utils/const";

export default function Projects() {
  const [activeBtn, setActiveBtn] = useState("all");
  const [activeLabel, setActiveLabel] = useState("All Projects");

  console.log(
    PROJECTS_DATA?.filter((item) =>
      item?.filter?.includes(activeBtn) ? item : null
    )
  );
  return (
    <Layout>
      <Banner title="Projects we have done " name="Projects" />

      <Box
        as="section"
        pt={{ base: "50px", md: "150px" }}
        pb="80px"
        bg={{ md: `url(${servicesBg}) no-repeat right bottom` }}
        bgSize={{ md: "cover" }}
      >
        <Container maxW="1280px" my={{ base: "1rem", md: "4.8rem" }}>
          <Box textAlign="center" mb="4.8rem">
            <HStack spacing="5px" justify="center">
              <HeadingImage />
              <Heading
                fontSize="13px"
                fontWeight={700}
                textTransform="uppercase"
              >
                Our Services
              </Heading>
            </HStack>
            <Heading
              fontSize="40px"
              fontWeight={700}
              my="22px"
              textTransform="uppercase"
            >
              Here are few Projects we have worked on.
            </Heading>
            <Text my="1rem">
              Explore a curated selection of our impressive projects, showcasing
              our expertise in diverse domains and highlighting our dedication
              to delivering exceptional results.
            </Text>
          </Box>
          <Box textAlign="center" mb="5rem">
            <ButtonGroup
              bg="#F2F2F2"
              borderRadius="8px"
              display={{ base: "none", md: "inline-flex" }}
            >
              {PROJECT_FILTER?.map((btn) => (
                <Button
                  mx={activeBtn === btn?.type ? "0rem" : "1rem"}
                  variant={activeBtn === btn?.type ? "solid" : "unstyled"}
                  onClick={() => setActiveBtn(btn?.type)}
                  key={btn?.label}
                >
                  {btn?.label}
                </Button>
              ))}
            </ButtonGroup>
            <Menu matchWidth={true}>
              <MenuButton
                bg="transparent"
                color="#5E5E5E"
                border="1px solid #5E5E5E"
                as={Button}
                w="100%"
                _active={{}}
                display={{ base: "block", md: "none" }}
              >
                <Flex justify="space-between">
                  <Text fontSize="16px">{activeLabel}</Text>
                  <ChevronDownIcon />
                </Flex>
              </MenuButton>
              <MenuList>
                {PROJECT_FILTER?.map((type) => (
                  <MenuItem
                    fontSize="16px"
                    key={type?.type}
                    onClick={() => {
                      setActiveBtn(type?.type);
                      setActiveLabel(type?.label);
                    }}
                  >
                    {type?.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
          {PROJECTS_DATA?.filter((item) =>
            item?.filter?.includes(activeBtn) ? item : null
          )?.map((item, i) => (
            <ProjectCard
              direction={i % 2 === 0 ? "row" : ""}
              {...item}
              key={item?.name}
            />
          ))}
        </Container>
      </Box>
    </Layout>
  );
}
