export default [
  {
    name: "Michael P",
    disc: "Blockyfy is one of the best companies in Blockchain projects. Their attention to detail from day one has been great, notifying and listing down creative ideas that are quite impressive. An effective team that maintains high standards and delivers on time. We are consistently working on a project with Blockyfy, and the progress is as per my expectations. They come highly recommended for Blockchain industry-based IT solutions.",
  },
  {
    name: "Mack Coulibaly",
    disc: "Blockyfy is a talented provider. Throughout the project, they worked ingeniously and collaboratively to successfully deliver the project on time and on-budget. The workmanship was perfect. I highly recommend their services",
  },
  {
    name: "Qasim Esa",
    disc: "Blockyfy is one of the few companies that excels in developing web3 apps, web, and mobile solutions. They consistently deliver before the deadline and exhibit high expertise in their work. I am thoroughly impressed and will definitely choose to work with them again for future updates. I highly recommend Blockyfy for its exceptional services.",
  },
  {
    name: "Ronelio Lim",
    disc: "Blockyfy is an expert in Blockchain solutions. They did an amazing job and completed all the tasks on time. Their skills in smart contracts, NFTs, NFT minting, and Solidity are exceptional. The work they delivered is top-notch and I am confident that I will work with them again in the future. I highly recommend Blockyfy for its outstanding expertise in Blockchain development.",
  },
];
