import React from "react";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";

export default function ProjectCard({
  thumbnail,
  type,
  name,
  disc,
  direction,
}: {
  type: string;
  name: string;
  disc: string;
  thumbnail: string;
  direction?: string;
}) {
  return (
    <Flex
      m={{ base: "10px", md: "1rem 0px" }}
      flexDir={{
        base: "column-reverse",
        md: direction ? "row" : "row-reverse",
      }}
      gap="40px"
      bg={{ base: "#F2F2F2", md: "#FBFBFB" }}
    >
      <Box flex={0.4}>
        <Image
          src={thumbnail}
          alt="earnx"
          h="100%"
          borderRadius={{
            base: "0px 0px 12px 12px",
            md: direction ? "8px 0px 0px 8px" : "0px 8px 8px 0px",
          }}
        />
      </Box>
      <Box
        flex={0.6}
        p={{ base: "20px", md: "40px " }}
        borderRadius={{
          base: "8px 8px 0px 0px",
          md: direction ? "0px 8px 8px 0px" : "8px 0px 0px 8px",
        }}
        box-shadow="0px 0px 40px rgba(0, 0, 0, 0.1)"
        textAlign={{ base: "center", md: "unset" }}
      >
        <Text
          fontSize="16px"
          fontWeight={700}
          bgGradient="linear(to-l, #22A7FA, #4026E2)"
          bgClip="text"
        >
          {type}
        </Text>
        <Heading fontSize="40px" fontWeight={700} my="8px">
          {name}
        </Heading>
        <Text>{disc}</Text>
      </Box>
    </Flex>
  );
}
