import React, { useState } from "react";
import Banner from "components/banner";
import Layout from "layout";
import JoinTeam from "assets/images/join-team.png";
import halfCircle from "assets/images/half-circle.jpg";
import BubblesBg from "assets/images/bubbles-bg.png";
import FillCircle from "assets/images/fill-circle.jpg";
import UnionArrow from "assets/images/union-arrow.jpg";
import CultureImg from "assets/images/culture-img.png";

import {
  Box,
  Container,
  Flex,
  Image,
  Text,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import Testimonial from "components/testimonial";
import { SearchIcon } from "@chakra-ui/icons";
import { JOBS, JOB_FILTERS } from "utils/const";
import recruitmentProcessData from "utils/data/recruitmentProcess.data";
import benefitsData from "utils/data/benefits.data";
import { intoView } from "utils/hekper";

export default function Career() {
  return (
    <Layout>
      <Banner title="EXPLORE TO BE A PART OF US" name="career" />
      <Box as="section" pt="150px" pb="100px" overflow="hidden">
        <Container maxW="1280px">
          <Flex justify="space-between" align="center">
            <Box
              w="50%"
              position="relative"
              display={{ base: "none", md: "block" }}
            >
              <Image src={JoinTeam} alt="buildImg" />
              <Image
                position="absolute"
                src={halfCircle}
                zIndex="-1"
                right="65%"
                bottom="-100px"
                alt="buildImg"
                transform="rotate(-180deg)"
              />
            </Box>
            <Box maxW="630px" textAlign={{ base: "center", md: "unset" }}>
              <Heading as="h6" variant="icon">
                EXPLORE OPPORTUNITIES
              </Heading>

              <Heading as="h2" mt="10px">
                JOIN THE COOLEST TEAM TO GROW YOUR CAREER
              </Heading>
              <Text my="30px">
                Are you interested in joining the BlockyFY team? {` We're`}{" "}
                always looking for talented and motivated individuals to join
                our team. Check out our career page to learn more about our
                current openings and apply today.
              </Text>
              <Button onClick={() => intoView("career")}>See Open Roles</Button>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box as="section" bg="lightGray" py="100px">
        <Container maxW="1280px" textAlign="center" id="career">
          <Heading as="h6" variant="icon">
            CAREER OPPORTUNITIES
          </Heading>
          <Heading my="25px">OPEN POSITIONS AT BLOCKYFY</Heading>
          <Text maxW="930px" mx="auto">
            Meet friendly, experienced and knowledgeable team members who make
            individuals and businesses pleased with their qualification. They
            often take their job seriously, carefully
          </Text>
          <CareerOptions />
        </Container>
      </Box>
      <Box as="section">
        <Image
          src={CultureImg}
          mx="auto"
          mb="-180px"
          w="100%"
          display={{ base: "none", md: "block" }}
        />
        <Container maxW="1280px">
          <Box bg="primaryGradient" rounded="8px">
            <Box
              bg={{ md: `url(${BubblesBg}) no-repeat bottom` }}
              bgSize={{ md: "contain" }}
              pt={{ base: "2rem", md: "170px" }}
              pb={{ base: "2rem", md: "135px" }}
              px={{ base: "8px", md: "0px" }}
              textAlign="center"
            >
              <Heading as="h3" color="white">
                CULTURE OF BLOCKYFY
              </Heading>
              <Text variant="primary" maxW="620px" mx="auto" mt="24px">
                Our company values a culture of innovation, collaboration, and
                continuous learning. Employees are encouraged to share their
                ideas, take risks, and push boundaries. We value diversity and
                teamwork, promoting a welcoming and inclusive atmosphere. We
                offer flexible hours and remote work options to maintain a
                healthy work-life balance. We aim to create a supportive and
                inspiring workplace that empowers employees.
              </Text>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box as="section" py="120px">
        <Container maxW="1280px">
          <Heading as="h6" variant="icon" textAlign="center">
            BENEFITS
          </Heading>
          <Heading my="25px" textAlign="center">
            HERE’S WHAT YOU GET
          </Heading>
          <Flex
            mt="40px"
            wrap="wrap"
            justify="center"
            gap="24px"
            sx={{
              "div:nth-child(even)": {
                bg: "primaryGradient",
                h4: { color: "white" },
                p: { color: "white" },
              },
            }}
          >
            {benefitsData?.map((benefits) => (
              <Box
                bg="brightGray"
                p="16px"
                rounded="8px"
                maxW="387px"
                w="100%"
                key={benefits?.heading}
              >
                {benefits?.icon}
                <Heading as="h4" fontSize="25px" my="24px" lineHeight="1">
                  {benefits?.heading}
                </Heading>
                <Text>{benefits?.desc}</Text>
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>
      <Testimonial />
      <Box as="section" py="120px">
        <Container maxW="1280px">
          <Heading as="h6" variant="icon" textAlign="center">
            Interview & Onboarding
          </Heading>
          <Heading my="25px" textAlign="center">
            OUR RECRUITMENT PROCESS
          </Heading>
          <Text maxW="900px" mx="auto" textAlign="center">
            At BlockyFY, we believe that the recruitment process is a critical
            part of building a strong team, and we take great care to ensure
            that we find the right candidate for each job opening.
          </Text>
          <Box
            sx={{ ".process:last-child": { ".arrow": { display: "none" } } }}
          >
            {recruitmentProcessData?.map((process, index) => (
              <HiringStep
                key={index}
                {...process}
                step={index + 1}
                dir={index % 2 !== 0 ? "rtl" : ""}
              />
            ))}
          </Box>

          <Box
            mt="40px"
            bg="primaryGradient"
            mx="auto"
            maxW="312px"
            h="4px"
            rounded="3px"
          />
        </Container>
      </Box>
    </Layout>
  );
}

function CareerOptions() {
  const [active, setActive] = useState("All Positions");
  return (
    <Flex
      mt="55px"
      gap="40px"
      textAlign="left"
      justify={{ base: "center", md: "start" }}
      flexDir={{ base: "column", md: "row" }}
    >
      <Box bg="brightGray" p="26px" maxW="393px" w="100%" rounded="8px">
        <FormControl
          border="1px solid"
          borderColor="borderColor"
          rounded="8px"
          p="16px 16px 10px"
          bg="white"
        >
          <FormLabel fontSize="13px" m="0">
            Find Job
          </FormLabel>
          <InputGroup>
            <Input
              border="none"
              type="text"
              focusBorderColor="transparent"
              p="0"
              placeholder="Search"
            />
            <InputRightElement>
              <SearchIcon />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        {JOB_FILTERS?.map((item) => (
          <Button
            key={item}
            variant="tab"
            className={active === item ? "active" : ""}
            mt="20px"
            onClick={() => setActive(item)}
          >
            {item}
          </Button>
        ))}
      </Box>
      <Flex gap="24px" flex="1" wrap="wrap" justify="center" display="none">
        {JOBS?.map((job) => (
          <Box
            key={job?.title}
            bg="brightGray"
            p="16px"
            maxW="377px"
            w="100%"
            rounded="8px"
          >
            <Heading fontSize="25px">{job?.title}</Heading>
            <Text my="16px">{job?.description}</Text>
            <Button>Apply Now</Button>
          </Box>
        ))}
      </Flex>
      <Text fontWeight="bold"> For Now there is no Job opportunity</Text>
    </Flex>
  );
}

function HiringStep({
  dir,
  title,
  desc,
  step,
}: {
  title: string;
  desc: string;
  step: number;
  dir?: string;
}) {
  return (
    <Flex
      gap="24px"
      position="relative"
      maxW="600px"
      align="center"
      dir={dir ? "rtl" : ""}
      ml={dir ? "auto" : ""}
      className="process"
      mt={{ base: "18px", md: "0px" }}
    >
      <Box position="relative">
        <Image transform={dir ? "rotate(-180deg)" : ""} src={FillCircle} />
        <Text
          bg="black"
          variant="primary"
          p="5px 12px"
          fontSize="12px"
          zIndex="1"
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          left={dir ? "" : "0"}
          right={dir ? "0" : ""}
        >
          Step 0{step}
        </Text>
      </Box>
      <Box>
        <Heading as="h3" fontSize="25px">
          {title}
        </Heading>
        <Text maxW="300px" fontSize="14px" mt="10px">
          {desc}
        </Text>
      </Box>
      <Image
        className="arrow"
        transform={dir ? "rotate(-180deg)" : ""}
        zIndex="-1"
        position="absolute"
        bottom="0"
        left={dir ? "" : "100px"}
        right={dir ? "100px" : ""}
        src={UnionArrow}
        display={{ base: "none", md: "block" }}
      />
    </Flex>
  );
}
