import blockchain from "assets/images/blockchain.jpg";
import web from "assets/images/web.jpg";
import mobile from "assets/images/mobile.jpg";
import design from "assets/images/design.jpg";
import qa from "assets/images/qa.jpg";

export const COMPANY_MENU = [
  {
    label: "About",
    path: "/about",
  },
  {
    label: "How do we do",
    path: "/how-do-we-do",
  },
  {
    label: "Life at BlockyFy",
    path: "/about",
  },
  {
    label: "Careers",
    path: "/career",
  },
];

export const SERVICES_MENU = [
  {
    label: "Blockchain Development",
    path: "/blockchain-dev",
  },
  {
    label: "Mobile Development",
    path: "/app-development",
  },
  {
    label: "UI/UX Designing",
    path: "/ui-ux-design-service",
  },
  {
    label: "Web Development",
    path: "/web-development",
  },
  {
    label: "Quality Assurance",
    path: "/qa-engineering",
  },
];

export const OTHERS_MENU = [
  {
    label: "Support Center",
    id: "contact",
  },
];

export const MENU = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "About",
    path: "/about",
  },
  {
    label: "Services",
    path: "/services",
    subMenu: SERVICES_MENU,
  },
  {
    label: "How do we do",
    path: "/how-do-we-do",
  },
  {
    label: "Projects",
    path: "/projects",
  },
  {
    label: "Career",
    path: "/career",
  },
  // {
  //   label: "Blogs",
  //   path: "/blogs",
  // },
];

export const JOB_FILTERS = [
  "All Positions",
  "Graphic / UI-UX Designing",
  "Frontend Development",
];

export const JOBS = [
  {
    title: "UI UX Designer",
    description:
      "Create intuitive, visually appealing user interfaces and seamless user experiences. Collaborate with cross-functional teams to deliver exceptional digital products. Strong design skills, wireframing, prototyping, and proficiency in design tools required.",
  },
  {
    title: "Frontend Developer",
    description:
      "Frontend Developer needed to create responsive, user-friendly web interfaces. Proficiency in HTML, CSS, JavaScript, and experience with frameworks such as React or Angular. Join our dynamic team today!",
  },
  {
    title: "Frontend Developer",
    description:
      "Frontend Developer needed to create responsive, user-friendly web interfaces. Proficiency in HTML, CSS, JavaScript, and experience with frameworks such as React or Angular. Join our dynamic team today!",
  },
];

export const PORTFOLIO_UTILS = [
  { label: "Years of Experience", value: "5+ Years" },
  { label: "Total Team Members", value: "50+" },
  { label: "Total Projects Done", value: "200+" },
];

export const SUBMENU_DETAILS = [
  {
    detail: `
    <h6>Blockchain Development</h6>
    <ul class="list">
    <li>Development Services</li>
    <li>Wallet</li>
    <li>APIs</li>
    <li>Contract</li>
    </ul>
    `,
    image: blockchain,
  },
  {
    detail: `
    <h6>Mobile App Development</h6>
    <ul class="list">
    <li>Development Services</li>
    <li>Custom Website</li>
    <li>Dashboard Application</li>
    </ul>
    `,
    image: mobile,
  },

  {
    detail: `
    <h6>UI/UX & Graphic Design</h6>
    <ul class="list">
    <li>User Research</li>
    <li>Ideation</li>
    <li>Wireframing</li>
    <li>Hi-Fi Design</li>
    <li> Prototyping</li>
    <li>Testing</li>
    </ul>
    `,
    image: design,
  },
  {
    detail: `
    <h6>Web Development</h6>
    <ul class="list">
    <li>Development Services</li>
    <li>Custom Website</li>
    <li>Dashboard Application</li>
    </ul>
    `,
    image: web,
  },
  {
    detail: `
    <h6>Quality Assurance</h6>
    <ul class="list">
    <li>Testing</li>
    <li>Unit Testing</li>
    <li> Automatic Testing</li>
    </ul>
    `,
    image: qa,
  },
];
export const PROJECT_FILTER = [
  {
    label: "All Projects",
    type: "all",
  },
  {
    label: "Blockchain Development",
    type: "blockchain",
  },
  {
    label: "Web Development",
    type: "web",
  },
  {
    label: "Mobile Application",
    type: "mobile",
  },
  {
    label: "UI/UX Design",
    type: "design",
  },
];
