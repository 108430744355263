import React from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/layout";
import Layout from "layout";
import chainHeader from "assets/images/blockchain-dev.jpg";
import { blockChainDevelopment } from "utils/data/blockchain.data";
import { GradientLogo } from "assets/icons";
import { Image } from "@chakra-ui/image";
import bitEthCoin from "assets/images/bitEthcoin.jpg";
export default function BlockChainDevelopment() {
  return (
    <Layout>
      <Box
        bg={`url(${chainHeader}) no-repeat center`}
        bgSize="100% 100%"
        h="512px"
        textAlign="center"
      >
        <Flex
          flexDir="column"
          gap="15px"
          justify="center"
          align="center"
          maxH="512px"
          h="100%"
        >
          <Heading fontSize="58px" fontWeight="900" color="white">
            BlockChain Development
          </Heading>
          <Text
            fontSize="18px"
            fontWeight="400"
            color="white"
            maxW="722px"
            w="100%"
          >
            Thinking about how to invest but don&apos;t know where to start?
            We&apos;re looking to make investing simpler, as you&apos;ll learn
            in our introduction to investing for beginners.
          </Text>
        </Flex>
      </Box>
      <Container maxW="1280px" my="100px">
        <Heading fontSize="42px" fontWeight="700" textAlign="center" mb="2rem">
          Our Services
        </Heading>
        <SimpleGrid minChildWidth="400px" spacing="1px" bg="#E4E4E7">
          {blockChainDevelopment?.map((item) => (
            <Box
              bg="white"
              height="290px"
              p="33px 45px"
              textAlign="center"
              key={item?.title}
            >
              <Center>
                <>{item?.icon}</>
              </Center>
              <Heading fontSize="21px" fontWeight="700" my="24px">
                {item?.title}
              </Heading>
              <Text>{item?.detail}</Text>
            </Box>
          ))}
        </SimpleGrid>
        <Box textAlign="center">
          <Center my="35px">
            <GradientLogo />
          </Center>
          <Text>
            With our diverse range of services, we help organizations embrace
            the potential of blockchain technology, whether it&apos;s for
            consulting, smart contract development, application development,
            private blockchains, integration, NFT marketplaces, fintech
            solutions, DeFi platforms, digital crypto wallets, or blockchain
            security audits. We are committed to delivering high-quality,
            secure, and scalable solutions that drive innovation and transform
            industries.
          </Text>
          <Image src={bitEthCoin} my="49px" />
        </Box>
      </Container>
    </Layout>
  );
}
