import {
  Experts,
  FasterTime,
  Flexibility,
  MaxROI,
  PredictibleCost,
  ReputableEmplyee,
  TestCoverage,
  YouGotWhat,
} from "assets/icons";

export const TypesOfTesting = [
  {
    name: "Functional Testing",
    description:
      "We make sure your software works in line with the business and functional requirements.",
  },
  {
    name: "Performance Testing",
    description:
      "Be confident that your product can work smoothly under pressure and may scale  up when your business grows.",
  },
  {
    name: "Compatibility Testing",
    description:
      "We test solution performance on over 250 physical devices. With that variety within our device fleet, we guarantee your application to fly on any platform.",
  },
  {
    name: "End-to-End Testing",
    description:
      "Our engineers develop real user scenarios and their replications to make sure your solution functions as expected from beginning to end.",
  },
  {
    name: "Usability Testing",
    description:
      "We test how your audience interacts with your software by making a detailed analysis of user actions and preferences.",
  },
  {
    name: "Security Testing",
    description:
      "Your software will comply with international security standards & industry certifications. We will detect & fix weaknesses, memory leaks & other bottlenecks.",
  },
];

export const WhyBlockyfy = [
  {
    image: Flexibility(),
    title: "Flexibility",
    detail:
      "Agile methodologies and best practices of software development that meet your business needs and requirements",
  },
  {
    image: MaxROI(),
    title: "Maximum ROI",
    detail:
      "Cost optimization approaches, CI/CD processes and automation tools for maximum ROI of your software development or QA project",
  },
  {
    image: PredictibleCost(),
    title: "Predictable Costs",
    detail:
      "Straight and transparent communication with customers and clear KPIs that guarantee predictable project costs",
  },
  {
    image: Experts(),
    title: "Experts for Your Project",
    detail:
      "Certified professionals who have worked with startups and all the way up to Fortune 500 companies",
  },
  {
    image: TestCoverage(),
    title: "Full-Scale Test Coverage",
    detail:
      "Test life cycle procedures that include all necessary types of testing",
  },
  {
    image: FasterTime(),
    title: "Faster Time to Market",
    detail:
      "Automation of the most time consuming and critical operations, which results in faster delivery and release to the market",
  },
  {
    image: YouGotWhat(),
    title: "You Get What You Need",
    detail:
      "Development of solutions according to your requirements and deadlines – no delays",
  },
  {
    image: ReputableEmplyee(),
    title: "Reputable Employer",
    detail:
      "Extensive talent pool: right personnel for your project in a timely manner, with an efficient cost to competence ratio",
  },
];

export const OurQAProcess = [
  {
    name: "Requirements Analysis",
    description:
      "Avoid expensive bug fixing and product reworking in the future. Blockyfy engineers ensure that the requirements are clear, complete, traceable and testable.",
  },
  {
    name: "Test Planning and Designing",
    description:
      "We develop a strategy, budget, schedule, resources and reporting procedures. With a clear scope of work and requirements, our QA team starts to design test cases and checklists.",
  },
  {
    name: "Test Execution",
    description:
      "Starting at the API and UI levels, our QA engineers add each bug to a defect-tracking system. Being in sync with the development process allows us to start fixing bugs as soon as possible.",
  },
  {
    name: "Process Optimization",
    description:
      "Modern tools and testing scripts help to optimize QA processes. Thanks to the automation of repetitive tasks, we can focus our time on strategic product initiatives.",
  },
  {
    name: "Reporting",
    description:
      "We provide a complete test report after each development iteration. Regular reports allow you to control the product quality and release schedule.",
  },
  {
    name: "Release Stage and Post-Production Support",
    description:
      "Once your product is released, we check with actual users and issues at the production level. We strive to satisfy our clients, providing the best-quality features for our solutions.",
  },
];
