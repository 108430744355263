import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import About from "views/about";
import BlockChainDevelopment from "views/blockchainDevelopment";
import Blog from "views/blogs";
import HowDoWeDo from "views/howDoWeDo";
import Career from "views/career";
import Landing from "views/landing";
import Projects from "views/projects";
import Services from "views/services";
import WebDevelopment from "views/webDevelopment";
import AppDevelopment from "views/appDev";
import YourWalletYourChoice from "views/yourWalletYourChoice";
import QAEngineering from "views/QAEngineering";
import DesignService from "views/designService";
import Verification from "views/verification";

const routes = [
  {
    path: "/",
    Component: Landing,
  },
  {
    path: "/about",
    Component: About,
  },
  {
    path: "/how-do-we-do",
    Component: HowDoWeDo,
  },
  {
    path: "/projects",
    Component: Projects,
  },
  {
    path: "/blockchain-dev",
    Component: BlockChainDevelopment,
  },
  {
    path: "/blogs",
    Component: Blog,
  },
  {
    path: "/services",
    Component: Services,
  },
  {
    path: "/career",
    Component: Career,
  },
  {
    path: "/web-development",
    Component: WebDevelopment,
  },
  {
    path: "/app-development",
    Component: AppDevelopment,
  },
  {
    path: "/your-wallet-your-choice",
    Component: YourWalletYourChoice,
  },
  {
    path: "/qa-engineering",
    Component: QAEngineering,
  },
  {
    path: "/ui-ux-design-service",
    Component: DesignService,
  },
  {
    path: "/site-verification",
    Component: Verification,
  },
];

export default function AppRoutes() {
  return (
    <Routes>
      {routes?.map(({ path, Component }) => (
        <Route
          key={path}
          element={
            // isPrivate ? (
            //   <PrivateRoute component={<Component />} />
            // ) : (
            <Component />
            // )
          }
          path={path}
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
