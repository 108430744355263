import React, { useState } from "react";
import {
  useMediaQuery,
  Flex,
  Text,
  Button,
  IconButton,
  Box,
  List,
  Slide,
  Fade,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { MENU, SUBMENU_DETAILS } from "utils/const";
import { NavLink, useNavigate } from "react-router-dom";
import { CloseMenuIcon, GradientLogo, HamburgerIcon } from "assets/icons";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { intoView } from "utils/hekper";

export default function Header() {
  const [currentMenu, setCurrentMenu] = useState(SUBMENU_DETAILS[0]);

  const [isLargerThan960] = useMediaQuery("(min-width: 960px)", { ssr: false });

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box as="header" zIndex="10" position="sticky" top="0" bg="white" px="12px">
      <Flex alignItems="center" maxW="1280px" justify="center" mx="auto">
        <Flex
          p={{ base: "14px", lg: "15px 0px" }}
          align="center"
          flex={{ base: "1", lg: "none" }}
          // bg="primaryGradient"
        >
          <NavLink to="/">
            {/* <Image src={logo} /> */}
            <GradientLogo />
          </NavLink>
          {!isLargerThan960 && <MobileMenu onClick={intoView} />}
        </Flex>
        {isLargerThan960 && (
          <Flex as="nav" mr="auto" align="center" position="relative">
            {MENU.map(({ label, path, subMenu }) => (
              <>
                <Text
                  onClick={() => intoView(path)}
                  as={NavLink}
                  to={path}
                  key={label}
                  variant="link"
                  py={{ base: "14px", lg: "15px" }}
                  onMouseEnter={() => subMenu && onOpen()}
                  onMouseLeave={() => subMenu && onClose()}
                  ml="25px"
                >
                  {label}
                  {subMenu && <ChevronDownIcon fontWeight="700" />}
                </Text>
                {subMenu && (
                  <Fade in={isOpen}>
                    <Flex
                      bg="white"
                      p="30px"
                      position="absolute"
                      top="100%"
                      left="0"
                      pointerEvents={isOpen ? "all" : "none"}
                      onMouseEnter={() => onOpen()}
                      onMouseLeave={() => onClose()}
                    >
                      <Box pr="30px" minW="250px">
                        {subMenu?.map(({ label, path }, i) => (
                          <Text
                            variant="link"
                            display="block"
                            as={NavLink}
                            to={path}
                            key={label}
                            onMouseEnter={() =>
                              setCurrentMenu(SUBMENU_DETAILS[i])
                            }
                            py="10px"
                          >
                            {label}
                          </Text>
                        ))}
                      </Box>
                      <Box
                        borderLeft="1px solid transparent"
                        borderColor="lightgray"
                        sx={{
                          ".list": { mt: "20px", li: { py: "5px" } },
                          color: "heading",
                        }}
                        px="30px"
                        minW="280px"
                      >
                        <Box
                          px="10px"
                          dangerouslySetInnerHTML={{
                            __html: currentMenu?.detail,
                          }}
                        />
                      </Box>
                      <Box
                        pl="30px"
                        minW="250px"
                        borderLeft="1px solid transparent"
                        borderColor="lightgray"
                      >
                        <Image src={currentMenu?.image} />
                      </Box>
                    </Flex>
                  </Fade>
                )}
              </>
            ))}
          </Flex>
        )}
        {isLargerThan960 && (
          <Button
            onClick={() => intoView("contact")}
            to="/#contact"
            as={NavLink}
            fontSize="14px"
          >
            Get in touch
          </Button>
        )}
      </Flex>
    </Box>
  );
}

function MobileMenu({ onClick }: { onClick: (path: string) => void }) {
  const { onToggle, isOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  };
  return (
    <>
      <IconButton
        ml="auto"
        aria-label="menu-icon"
        onClick={() => onToggle()}
        icon={isOpen ? <CloseMenuIcon /> : <HamburgerIcon />}
        variant="unstyled"
      />
      <Box as={Slide} in={isOpen} sx={{ pointerEvents: "none" }}>
        <List variant="mobMenu" mt="66px">
          {MENU.map(({ label, path }) => (
            <Text
              onClick={() => onClick(path)}
              as={NavLink}
              to={path}
              key={label}
              variant="link"
              display="block"
              textAlign="center"
              my="16px"
            >
              {label}
            </Text>
          ))}
          <Button
            display="block"
            mx="auto"
            w="208px"
            onClick={() => {
              navigateToContact();
              onClose();
            }}
          >
            Contact Us
          </Button>
        </List>
      </Box>
    </>
  );
}
