import React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { HeadingImage, LineBullet } from "assets/icons";
import aboutbg from "assets/images/aboutbg.png";
import teamwork from "assets/images/teamWork.jpg";
import lifeAtBlockyfy from "assets/images/lifeAtblockyfy.jpg";
import ContactUsCard from "components/contactCard";
import Testimonial from "components/testimonial";
import Layout from "layout";
import ExperienceMemberProjectCard from "components/portfolioUtils";
import Banner from "components/banner";
import aboutImg1 from "assets/images/aboutImg1.jpg";
import aboutImg2 from "assets/images/aboutImg2.jpg";
import aboutImg3 from "assets/images/aboutImg3.jpg";
import aboutImg4 from "assets/images/aboutImg4.jpg";
import aboutImg5 from "assets/images/aboutImg5.jpg";
import aboutImg6 from "assets/images/aboutImg6.jpg";
import { NavLink } from "react-router-dom";

export default function About() {
  return (
    <Layout>
      <Banner title="ABOUT US" name="About" />

      <Box
        bg={{ md: `url(${aboutbg}) left center no-repeat` }}
        bgSize={{ md: "contain" }}
        my="160px"
      >
        <Container maxW="1280px">
          <Flex
            gap={{ base: "24px", md: "118px" }}
            flexDir={{ base: "column", md: "row" }}
          >
            <Box flex={0.4} display={{ base: "none", md: "block" }}>
              <Image src={teamwork} />
            </Box>
            <Box flex={0.6}>
              <HStack spacing="5px" justify={{ base: "center", md: "unset" }}>
                <HeadingImage />
                <Heading fontSize="13px" fontWeight={700}>
                  ABOUT BLOCKYFY
                </Heading>
              </HStack>
              <Heading
                fontSize="40px"
                fontWeight={700}
                my="22px"
                textAlign={{ base: "center", md: "unset" }}
              >
                WE HELP IN BUILDING YOUR DREAMS
              </Heading>
              <Text my="1rem" textAlign={{ base: "center", md: "unset" }}>
                We are a leading IT services provider company specializing in
                blockchain development. With a team of highly skilled and
                experienced professionals, we offer customized solutions to help
                businesses leverage the power of blockchain technology.
                <br />
                <br />
                Our team is dedicated to delivering high-quality and innovative
                solutions that help businesses transform and grow. We have a
                deep understanding of blockchain technology and its potential
                applications across various industries, including finance,
                healthcare, logistics, and more.
              </Text>
              <List
                display="Flex"
                flexWrap="wrap"
                bg={{ base: "#F2F2F2", md: "transparent" }}
                borderRadius="8px"
                p="8px"
              >
                {[
                  "Expertise",
                  "Customized Solutions",
                  "security",
                  "collaboration",
                  "cost effective solutions",
                ].map((item) => (
                  <ListItem
                    key={item}
                    fontWeight={700}
                    fontSize="13px"
                    flex="50%"
                    display="flex"
                    alignItems="center"
                    textTransform="uppercase"
                    mb="18px"
                  >
                    <ListIcon as={LineBullet} />
                    {item}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Flex>
        </Container>
      </Box>
      <ExperienceMemberProjectCard />
      <ContactUsCard />
      <Container maxW="1280px" mb="18px">
        <Flex
          gap={{ base: "24px", md: "118px" }}
          flexDir={{ base: "column", md: "row" }}
        >
          <Box flex={0.6} textAlign={{ base: "center", md: "unset" }}>
            <HStack spacing="5px">
              <HeadingImage />
              <Heading fontSize="13px" fontWeight={700}>
                Life at blockyfy
              </Heading>
            </HStack>
            <Heading
              fontSize="40px"
              fontWeight={700}
              my="24px"
              textTransform="uppercase"
            >
              Techies, innovators, developers, and free-thinkers… you’ve come to
              the right place.
            </Heading>
            <Text mb="1rem">
              Our commitment to customer service sets us apart from other IT
              companies. We pride ourselves on being responsive, reliable, and
              easy to work with. We believe that communication is key, and we
              make sure to keep our clients informed every step of the way.
              Whether you&apos;re a small business looking to outsource your IT
              needs or a large enterprise in need of a customized solution, we
              are here to help. Contact us today to learn more about our
              services and how we can help your business thrive in the digital
              age.
            </Text>

            <Button size="lg" as={NavLink} to="/career">
              See Available Jobs
            </Button>
          </Box>
          <Box flex={0.4} display={{ base: "none", md: "block" }}>
            <Image src={lifeAtBlockyfy} />
          </Box>
        </Flex>
      </Container>
      <Container maxW="1280px">
        <Grid
          // h="90vh"
          templateRows=" minmax(200px, 300px)"
          templateColumns="2fr 1fr minmax(50px, 60px) 1fr"
          gap={3}
        >
          <GridItem
            as={Image}
            src={aboutImg1}
            w="100%"
            h="100%"
            rowSpan={[1, 2]}
            colSpan={[4, 1]}
            objectFit="cover"
            rounded="8px"
          />

          <GridItem
            as={Image}
            src={aboutImg2}
            w="100%"
            h="100%"
            colSpan={[1, 2]}
            objectFit="cover"
            rounded="8px"
          />
          <GridItem
            as={Image}
            src={aboutImg4}
            w="100%"
            h="100%"
            colSpan={1}
            rowSpan={[1, 2]}
            objectFit="cover"
            rounded="8px"
          />
          <GridItem
            as={Image}
            src={aboutImg3}
            w="100%"
            h="100%"
            colSpan={2}
            rowSpan={1}
            objectFit="cover"
            rounded="8px"
          />
          <GridItem
            as={Image}
            src={aboutImg5}
            w="100%"
            h="100%"
            colSpan={2}
            objectFit="cover"
            rounded="8px"
          />
          <GridItem
            as={Image}
            src={aboutImg6}
            w="100%"
            h="100%"
            colSpan={2}
            objectFit="cover"
            rounded="8px"
          />
        </Grid>
      </Container>
      <Testimonial />
    </Layout>
  );
}
