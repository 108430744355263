import { Box, Container, Heading, IconButton, Text } from "@chakra-ui/react";
import React from "react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import Slider from "react-slick";
import TESTIMONIALS_DATA from "utils/data/testimonials.data";

export default function Testimonial() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <Box bg="lightGray" py="100px">
      <Container maxW="1280px" textAlign={{ base: "center", md: "left" }}>
        <Heading fontSize="13px" as="h6" variant="icon">
          Testimonials{" "}
        </Heading>
        <Heading
          fontSize={{ base: "32px", md: "40px" }}
          fontWeight={700}
          my="25px"
          textTransform="uppercase"
        >
          What our clients say about us
        </Heading>
        <Slider {...settings}>
          {TESTIMONIALS_DATA?.map(({ name, disc }) => (
            <Box px="8px" key={name}>
              <Box
                maxW="400px"
                w="100%"
                bg="brightGray"
                p="16px 21px"
                m="8px auto"
              >
                <Heading fontSize="20px" fontWeight="600">
                  {name}
                </Heading>
                <Text mt="1rem">{disc}</Text>
              </Box>
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
}

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <IconButton
      aria-label="next"
      rounded="full"
      position="absolute"
      borderColor="#22A7FA"
      size="sm"
      right={{ base: "0", md: "35px" }}
      top="106%"
      variant="outline"
      onClick={onClick}
    >
      <ChevronRightIcon fontSize="25px" color="#22A7FA" />
    </IconButton>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;

  return (
    <IconButton
      aria-label="back"
      rounded="full"
      position="absolute"
      borderColor="#22A7FA"
      variant="outline"
      size="sm"
      left="unset"
      right="78px"
      top="106%"
      onClick={onClick}
    >
      <ChevronLeftIcon fontSize="25px" color="#22A7FA" />
    </IconButton>
  );
}
