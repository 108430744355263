export default [
  {
    num: "01",
    title: "User Research",
    disc: `The study of learning what end users of a system or product
        need and want, then employing those insights to enhance the
        design process for products, services or software.`,
  },
  {
    num: "02",
    title: "Define / Ideate",
    disc: `State your users needs and problems, after defining a finalised document. Challenging assumptions and crafting ideas to solve a particular problem.`,
  },
  {
    num: "03",
    title: "Design UI",
    disc: `Designing full fledged prototypes for the design and presenting it to the client for further processing and revisions.`,
  },
  {
    num: "04",
    title: "UX Audit",
    disc: `Making sure the design is serving it’s purpose as per the requirements or not. If not! revising the process again to fill the gap between a user and the product.`,
  },
];
