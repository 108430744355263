import React from "react";
import Layout from "layout";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import ContactUsCard from "components/contactCard";
import Testimonial from "components/testimonial";
import buildImg from "assets/images/build.png";
import halfCircle from "assets/images/half-circle.jpg";
import Banner from "components/banner";
import ServiceCard from "components/servicesCard";
import servicesBg from "assets/images/services-bg.png";
import SERVICES_DATA from "utils/data/services.data";
import { intoView } from "utils/hekper";

export default function Services() {
  return (
    <Layout>
      <Banner title="SERVICES WE PROVIDE" name="Services" />
      <Box as="section" pt="150px" pb="100px" overflow="hidden">
        <Container maxW="1280px">
          <Flex justify="space-between">
            <Box maxW="490px">
              <Heading as="h6" variant="icon">
                OUR SERVICES
              </Heading>

              <Heading as="h2" mt="10px">
                WHAT MAKE US DIFFERENT FROM OTHER
              </Heading>
              <Text my="30px">
                {`At our company, we believe that providing digital services is not just about delivering a product or completing a project. It's about building long-term partnerships with our clients and delivering real value to their business. That's why we go above and beyond to understand our clients' goals, challenges, and opportunities, and tailor our solutions to meet their unique needs.`}
                <br />
                <br />
                {`What sets us apart from others in the industry is our focus on innovation and continuous improvement. We are constantly exploring new technologies and techniques, and leveraging the latest industry trends to deliver cutting-edge solutions that drive results. We don't just follow the status quo; we challenge it and push the boundaries of what's possible.`}
              </Text>
              <Button onClick={() => intoView("contact")} fontSize="12px">
                Contact Now
              </Button>
            </Box>
            <Box
              w="50%"
              position="relative"
              display={{ base: "none", md: "block" }}
            >
              <Image src={buildImg} alt="buildImg" />
              <Image
                position="absolute"
                src={halfCircle}
                zIndex="-1"
                left="62%"
                bottom="-100px"
                alt="buildImg"
              />
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box
        as="section"
        pt="150px"
        pb="80px"
        bg={`url(${servicesBg}) no-repeat right bottom`}
        bgSize="cover"
      >
        <Container maxW="1250px" textAlign="center">
          <Heading as="h6" variant="icon">
            OUR SERVICES
          </Heading>
          <Heading my="25px" fontSize={{ base: "32px", md: "40px" }}>
            WE PROVIDE DIFFERENT IT SERVICES
          </Heading>
          <Text maxW="940px" mx="auto" fontSize={{ base: "12px", md: "16px" }}>
            Our services include the development of custom NFT platforms, which
            allow businesses and individuals to create, manage, and trade unique
            digital assets. We also offer white label wallet services with our
            commitment to provide secure and easy-to-use solutions for storing
            and managing your digital assets. Our wallet services offer a range
            of features, including support for multiple cryptocurrencies, the
            ability to easily send, receive and swap assets across multi chains,
            and robust security to protect your assets from potential threats.
          </Text>
          <Flex gap="35px" wrap="wrap" mt="60px" justify="center">
            {SERVICES_DATA?.map((item) => (
              <ServiceCard {...item} key={item?.title} />
            ))}
          </Flex>
          <Button
            onClick={() => intoView("contact")}
            mt="45px"
            size="lg"
            mx="auto"
            display="flex"
            fontSize="12px"
          >
            Let’s Discuss
          </Button>
        </Container>
      </Box>
      <ContactUsCard />
      <Testimonial />
    </Layout>
  );
}
