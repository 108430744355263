import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Flex,
  Heading,
  Highlight,
  IconButton,
  Image,
  Slide,
  SlideFade,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Footer from "components/footer";
import Header from "components/header";
import Banner1 from "assets/images/banner1.jpg";
import Banner2 from "assets/images/Banner2.jpg";
import Banner3 from "assets/images/Banner3.jpg";
import buildImg from "assets/images/build.png";
import helpAllImg from "assets/images/help-all.jpg";
import Help1 from "assets/images/help-1.jpg";
import Help2 from "assets/images/help-2.jpg";
import Help3 from "assets/images/help-3.jpg";
import servicesBg from "assets/images/services-bg.png";
import EarnXImg from "assets/images/lates-project.png";
import halfCircle from "assets/images/half-circle.jpg";
import ServiceCard from "components/servicesCard";
import Testimonial from "components/testimonial";
import ContactUsCard from "components/contactCard";
import PortfolioUtils from "components/portfolioUtils";
import SERVICES_DATA from "utils/data/services.data";
import PROJECTS_DATA from "utils/data/projects.data";
import Slider from "react-slick";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { Link, NavLink } from "react-router-dom";
import { intoView } from "utils/hekper";
import mouse from "assets/images/mouse.png";
import ambitions from "assets/images/ambitions.png";
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 676,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 567,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        className: "slide-animation",
      },
    },
  ],
};

const settings2 = {
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        dots: true,
        centerMode: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        dots: true,
        centerMode: true,
      },
    },
  ],
};
const settings3 = {
  dots: false,
  fade: true,
  infinite: true,
  autoplay: true,
  autoplayspeed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <SampleNextArrow top="88%" right="122px" size="md" color="white" />
  ),
  prevArrow: (
    <SamplePrevArrow top="88%" right="175px" size="md" color="white" />
  ),
  responsive: [
    {
      breakpoint: 991,
      settings: {
        arrows: false,
      },
    },
  ],
};
export default function Landing() {
  return (
    <>
      <Header />
      <Box position="relative">
        <Slider className="my-unique-slider" {...settings3}>
          <BannerCard img={Banner1} />
          <BannerCard img={Banner2} />
          <BannerCard img={Banner3} />
        </Slider>
        <Image
          src={mouse}
          mx="auto"
          pos="absolute"
          bottom="-50px"
          left={{ base: "42%", md: "48%" }}
          zIndex={9}
        />
      </Box>
      <Box
        as="section"
        pt={{ base: "60px", md: "150px" }}
        pb={{ base: "60px", md: "100px" }}
        overflow="hidden"
      >
        <Container maxW="1280px">
          <Flex justify="space-between">
            <Box maxW="490px" textAlign={{ base: "center", md: "left" }}>
              <Heading as="h6" variant="icon">
                INTRO TO BLOCKYFY
              </Heading>
              <Heading
                as="h2"
                mt="10px"
                fontSize={{ base: "32px", md: "40px" }}
              >
                WE HELP IN BUILDING YOUR DREAMS
              </Heading>
              <Text my="30px" fontSize={{ base: "12px", md: "16px" }}>
                Our commitment to customer service sets us apart from other IT
                companies. We pride ourselves on being responsive, reliable, and
                easy to work with. We believe that communication is key, and we
                make sure to keep our clients informed every step of the way.
                Whether {`you're`} a small business looking to outsource your IT
                needs or a large enterprise in need of a customized solution, we
                are here to help. Contact us today to learn more about our
                services and how we can help your business thrive in the digital
                age.
              </Text>
              <Button
                as={NavLink}
                to="/how-do-we-do"
                display={{ base: "none", md: "inline-flex" }}
                fontSize="12px"
              >
                Learn More
              </Button>
            </Box>
            <Box
              w="50%"
              position="relative"
              display={{ base: "none", md: "block" }}
            >
              <Image src={buildImg} alt="buildImg" />
              <Image
                position="absolute"
                src={halfCircle}
                zIndex="-1"
                left="62%"
                bottom="-100px"
                alt="buildImg"
              />
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box as="section">
        <Container
          maxW="1000px"
          textAlign="center"
          position="relative"
          zIndex="1"
        >
          <Heading as="h6" variant="icon">
            WHAT DO WE DO
          </Heading>
          <Heading fontSize={{ base: "32px", md: "40px" }}>
            DEALING IN ALL PROFESSIONAL IT SERVICES
          </Heading>
          <Box mt="40px">
            <Slider {...settings2}>
              <HelpCard img={Help1} title="Following the best methods" />
              <HelpCard img={Help2} title="Helping New Businesses & startups" />
              <HelpCard img={Help3} title="Providing Wide range of services" />
            </Slider>
          </Box>
        </Container>
        <Box
          bg={`url(${helpAllImg}) no-repeat center`}
          bgSize="cover"
          position="relative"
          p="240px 0 65px"
          mt="-150px"
        >
          <Box
            bg="#101010"
            mixBlendMode="saturation"
            position="absolute"
            w="100%"
            h="100%"
            top="0"
          />
          <Box
            bg="primaryGradient"
            mixBlendMode="multiply"
            position="absolute"
            w="100%"
            h="100%"
            top="0"
          />
          <Container
            position="relative"
            zIndex="1"
            textAlign="center"
            maxW="740px"
          >
            <Text variant="primary">
              Blockyfy is a leading provider of blockchain technology, we are
              committed to helping businesses and individuals leverage the power
              of this innovative technology to improve their operations and
              enhance their security. Our team of experts has extensive
              experience in the blockchain space, and is dedicated to providing
              top-quality solutions and support.
            </Text>
            <Button as={Link} to="/services" variant="primary" mt="30px">
              <span>Explore All Services</span>
            </Button>
          </Container>
        </Box>
      </Box>
      <Box
        as="section"
        pt={{ base: "70px", md: "150px" }}
        pb={{ base: "70px", md: "80px" }}
        bg={`url(${servicesBg}) no-repeat right bottom`}
        bgSize="cover"
      >
        <Container maxW="1250px">
          <Heading as="h6" variant="icon">
            OUR SERVICES
          </Heading>
          <Heading my="25px" fontSize={{ base: "32px", md: "40px" }}>
            WE PROVIDE DIFFERENT IT SERVICES
          </Heading>
          <Text maxW="940px" fontSize={{ base: "12px", md: "16px" }}>
            Our services include the development of custom NFT platforms, which
            allow businesses and individuals to create, manage, and trade unique
            digital assets. We also offer white label wallet services with our
            commitment to provide secure and easy-to-use solutions for storing
            and managing your digital assets. Our wallet services offer a range
            of features, including support for multiple cryptocurrencies, the
            ability to easily send, receive and swap assets across multi chains,
            and robust security to protect your assets from potential threats.
          </Text>
          <Flex gap="35px" wrap="wrap" mt="40px" justify="center">
            {SERVICES_DATA?.map((item) => (
              <ServiceCard {...item} key={item?.title} />
            ))}
          </Flex>
          <Button
            mt="45px"
            mx="auto"
            display="flex"
            fontSize="12px"
            onClick={() => intoView("contact")}
          >
            Let’s Discuss
          </Button>
        </Container>
      </Box>
      <PortfolioUtils />
      <Box as="section" px="12px">
        <Container
          maxW="1280px"
          bg="#F2F2F2"
          rounded="16px"
          p={{ base: "20px", md: "24px 123.5px 0px" }}
          my="120px"
        >
          <Flex gap="56px">
            <Box
              flex={{ base: "1", md: "0.5" }}
              mt={{ base: "0px", md: "72px" }}
              mb={{ base: "0px", md: "20px" }}
              p={{ base: "20px", md: "0px" }}
              textAlign={{ base: "center", md: "unset" }}
            >
              <Heading as="h6" variant="icon">
                Our top product
              </Heading>
              <Heading fontSize="39px" my="24px" fontWeight="700">
                E-Wallet Apps to Scale Ambitions
              </Heading>
              <Text fontSize="18px">
                Integrating custom wallets inside your app with custom branding,
                best in class security, in less than 10 lines of code.
              </Text>
              <Button mt="24px">Read More</Button>
            </Box>
            <Box
              flex={0.5}
              display={{ base: "none", md: "block" }}
              alignSelf="end"
            >
              <Image src={ambitions} />
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box as="section" my={{ base: "40px", md: "125px" }} position="relative">
        <Container maxW="940px" textAlign="center">
          <Heading as="h6" variant="icon">
            RECENT PROJECT
          </Heading>
          <Heading as="h2" my="25px">
            EARNX
          </Heading>
          <Text fontSize={{ base: "12px", md: "16px" }}>
            EarnX Analytics Dashboard is a web-based platform designed to
            provide users with valuable insights and analytics related to NFTs.
            The dashboard features a sleek and user-friendly interface that
            allows users to easily navigate and analyze NFT data in real-time.
            EarnX is the perfect tool for anyone looking to gain valuable
            insights and analytics related to the rapidly growing NFT market.
            With its comprehensive features and user-friendly interface, the
            dashboard provides users with the data and insights they need to
            make informed decisions and stay ahead of the curve in this exciting
            and dynamic industry.
          </Text>
          <Image src={EarnXImg} />
        </Container>
        <Box
          h={{ base: "15%", md: "29.165%" }}
          bg="primaryGradient"
          position="absolute"
          bottom={{ base: "3%", md: "6%" }}
          zIndex="-1"
          w="100%"
        />
      </Box>
      <Box
        as="section"
        bg="lightGray"
        py={{ base: "70px", md: "125px" }}
        textAlign={{ base: "center", md: "left" }}
      >
        <Container maxW="1250px">
          <Heading as="h6" variant="icon">
            PROJECTS
          </Heading>
          <Heading
            my="25px"
            maxW="940px"
            fontSize={{ base: "32px", md: "40px" }}
          >
            EXPLORE OUR LATEST PROJECTS AND DISCOVER OUR INNOVATIVE SOLUTIONS
          </Heading>
          <Text maxW="940px" fontSize={{ base: "12px", md: "16px" }}>
            Our project section showcases some of our most successful and
            innovative projects that we have completed for a diverse range of
            clients across various industries. From mobile applications to web
            development and everything in between, our portfolio highlights our
            expertise in creating customized solutions that meet the unique
            needs of each of our clients. Our project section is a testament to
            our commitment to excellence and our ability to deliver high-quality
            solutions that drive results and exceed expectations.
          </Text>
          <Box mt="25px">
            <Slider {...settings}>
              {PROJECTS_DATA?.map((item) => (
                <ProjectVisitCard key={item?.name} {...item} />
              ))}
            </Slider>
          </Box>
        </Container>
      </Box>
      <ContactUsCard />
      <Testimonial />
      <Footer />
    </>
  );
}

function HelpCard({ img, title }: { img: string; title: string }) {
  return (
    <Box px="8px">
      <Box
        position="relative"
        bg="white"
        _hover={{
          cursor: "pointer",
          div: { bgClip: "unset", color: "white" },
          ".layer": { opacity: 0 },
        }}
        rounded="8px"
        overflow="hidden"
      >
        <Box
          transition="all 0.3s ease"
          _hover={{
            transform: "scale(1.1)",
          }}
        >
          <Image maxH="265px" h="100%" w="100%" objectFit="cover" src={img} />
        </Box>
        <Box
          bg="#101010"
          mixBlendMode="saturation"
          position="absolute"
          w="100%"
          h="100%"
          top="0"
          maxH="265px"
          className="layer"
          pointerEvents="none"
        />
        <Box
          bg="primaryGradient"
          bgClip="text"
          p="16px 24px"
          fontSize="20px"
          textTransform="uppercase"
          fontWeight="700"
          zIndex={1}
          pos="relative"
          transition="all 0.3s ease"
        >
          {title}
        </Box>
      </Box>
    </Box>
  );
}

function ProjectVisitCard({
  coverImg,
  type,
  link,
  name,
}: {
  name: string;
  coverImg: string;
  link: string;
  type: string;
}) {
  // const isDesktop = useBreakpointValue({ base: false, md: true });
  const { isOpen, onToggle } = useDisclosure();

  // const handleMouseEnter = () => {
  //   if (isDesktop) {
  //     onToggle();
  //   }
  // };

  // const handleMouseLeave = () => {
  //   if (isDesktop) {
  //     onToggle();
  //   }
  // };

  // if (!isDesktop && !isOpen) {
  //   onToggle();
  // }

  return (
    <Box px="8px">
      <Box
        onMouseEnter={() => onToggle()}
        onMouseLeave={() => onToggle()}
        rounded="8px"
        position="relative"
        overflow="hidden"
        maxW="290px"
        w="100%"
        // transition="all 2s ease-out"
        _hover={{ ".layer": { opacity: "1" }, border: "3px solid #4026E2" }}
        textAlign="center"
        minH="370px"
        mx="auto"
        // sx={{
        //   "@media (max-width: 768px)": {
        //     border: "3px solid #4026E2",
        //     ".layer": {
        //       opacity: "1",
        //     },
        //   },
        //   "@media (min-width: 769px)": {
        //     "&:hover": {
        //       ".layer": {
        //         opacity: "1",
        //       },
        //       border: "3px solid #4026E2",
        //     },

        //   },
        // }}
      >
        <Image src={coverImg} />
        <Box
          bg="#101010"
          mixBlendMode="saturation"
          position="absolute"
          w="100%"
          h="100%"
          top="0"
        />
        <Box
          bg="linear-gradient(180deg, rgba(64, 38, 226, 0) 0%, #4026E2 134.95%)"
          position="absolute"
          w="100%"
          h="100%"
          top="0"
          opacity="0"
          className="layer"
        />
        <Slide
          in={isOpen}
          direction="top"
          style={{ position: "absolute", height: "35px" }}
        >
          <Text
            variant="primary"
            bg="primaryGradient"
            left="50%"
            p="8px"
            maxW="155px"
            w="100%"
            transform="translateX(-50%)"
            rounded="0 0 8px 8px"
            position="absolute"
            top="0"
          >
            {type}
          </Text>
        </Slide>
        <SlideFade in={isOpen} offsetY="10px">
          <Box py="30px" position="absolute" bottom="0" w="100%">
            <Text variant="primary" as="strong" fontSize="20px">
              {name}
            </Text>
            {link && (
              <Button
                variant="primary"
                mx="auto"
                mt="15px"
                display="flex"
                as="a"
                href={link}
                target="_blank"
                w="max-content"
              >
                <span>Visit Now</span>
              </Button>
            )}
          </Box>
        </SlideFade>
      </Box>
    </Box>
  );
}
function BannerCard({ img }: { img: string }) {
  return (
    <Box
      as="section"
      bg={`url(${img}) no-repeat center`}
      bgSize="cover"
      position="relative"
      zIndex="1"
      overflow="hidden"
      className="my-slider-slide"
    >
      <Box
        bg="#101010"
        mixBlendMode="normal"
        position="absolute"
        w="100%"
        h="100%"
        top="0"
        opacity={0.6}
      ></Box>
      <Box
        // bg="#101010"
        // mixBlendMode="saturation"
        position="absolute"
        w="100%"
        h="100%"
        top="0"
      ></Box>
      <Box
        background="#0065FD"
        mixBlendMode="soft-light"
        transform="rotate(28.61deg)"
        position="absolute"
        w="8%"
        h="120%"
        top="-8%"
        right="8%"
      />
      <Container maxW="1280px" position="relative" zIndex="2">
        <Center
          minH="calc(100vh - 65px)"
          flexDirection="column"
          textAlign="center"
          maxW="800px"
          mx="auto"
        >
          <Heading
            color="white"
            textTransform="uppercase"
            fontSize={{ base: "40px", lg: "86px" }}
          >
            <Highlight
              query="&"
              styles={{
                background: "var(--chakra-colors-primaryGradient)",
                backgroundClip: "text",
                fontFamily: "'Rufina', serif",
              }}
            >
              We design & build IT Solutions
            </Highlight>
          </Heading>
          <Text
            variant="primary"
            my="35px"
            fontSize={{ base: "14px", md: "16px" }}
          >
            At our company, we understand that technology is constantly evolving
            and that staying ahead of the curve is essential for success.{" "}
            {`That's`} why we offer a wide range of IT services designed to help
            our clients optimize their operations and achieve their goals.
          </Text>
          <ButtonGroup>
            <Button
              size="lg"
              fontSize="12px"
              onClick={() => intoView("contact")}
            >{`Let's Discuss`}</Button>
            <Button
              fontSize="12px"
              size="lg"
              as={NavLink}
              to="/services"
              variant="primary"
            >
              <span>Explore More</span>
            </Button>
          </ButtonGroup>
        </Center>
      </Container>
    </Box>
  );
}
function SampleNextArrow(props: any) {
  const { onClick, top, right, size, color } = props;
  return (
    <IconButton
      aria-label="next"
      rounded="full"
      position="absolute"
      borderColor={color ? color : "#22A7FA"}
      size={size ? size : "sm"}
      right={right ? right : "35px"}
      top={top ? top : "106%"}
      variant="outline"
      onClick={onClick}
      _hover={{
        bg: "transparent",
      }}
    >
      <ChevronRightIcon fontSize="25px" color={color ? color : "#22A7FA"} />
    </IconButton>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick, top, right, size, color } = props;

  return (
    <IconButton
      aria-label="back"
      rounded="full"
      position="absolute"
      borderColor={color ? color : "#22A7FA"}
      variant="outline"
      size={size ? size : "sm"}
      left="unset"
      right={right ? right : "78px"}
      top={top ? top : "106%"}
      onClick={onClick}
      zIndex={1}
      _hover={{
        bg: "transparent",
      }}
    >
      <ChevronLeftIcon fontSize="25px" color={color ? color : "#22A7FA"} />
    </IconButton>
  );
}
