import React from "react";
import Footer from "components/footer";
import Header from "components/header";

export default function Layout({
  children,
  hideContact,
}: {
  children: JSX.Element[] | JSX.Element;
  hideContact?: boolean;
}) {
  return (
    <>
      <Header />
      {children}
      <Footer hideContact={hideContact} />
    </>
  );
}
