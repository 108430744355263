import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import headingIcon from "assets/images/heading-icon.png";
const theme = {
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Roboto', sans-serif",
    label: "'Roboto', sans-serif",
  },
  colors: {
    primaryGradient:
      "linear-gradient(125.99deg, #22A7FA 27.14%, #4026E2 91.25%)",
    OAI: "linear-gradient(141.01deg, #00BFFF 0%, #4B0082 108.14%)",
    secondary: "#0A1726",
    text: "#5E5E5E",
    background: "#ffffff",
    error: "#E84A4A",
    heading: "#101010 ",
    borderColor: "#C5C5C5",
    lightGray: "#FBFBFB",
    brightGray: "#F2F2F2",
  },
  components: {
    Text: {
      baseStyle: { color: "text" },
      variants: {
        primary: { color: "white" },
        secondary: { bg: "OAI", bgClip: "text" },
        link: {
          fontWeight: 400,
          color: "heading",
          "&.active": {
            fontWeight: 500,
            bg: "primaryGradient",
            bgClip: "text",
            svg: {
              color: "#4026E2",
            },
          },
          _hover: {
            bg: "primaryGradient",
            bgClip: "text",
            fontWeight: 500,
            svg: {
              color: "#4026E2",
            },
          },
        },
      },
    },
    Heading: {
      baseStyle: { color: "heading", fontSize: "40px" },
      variants: {
        primary: { color: "white" },
        icon: {
          fontSize: "13px",
          _before: {
            content: `url(${headingIcon})`,
            display: "inline-block",
            mr: "5px",
            verticalAlign: "middle",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "400",
      },
      variants: {
        solid: {
          border: "none",
          color: "white",
          bg: "primaryGradient",
          fontSize: "14px",
          _hover: "none",
        },
        primary: {
          fontSize: "14px",
          bg: "white",
          color: "secondary",
          span: { bg: "primaryGradient", bgClip: "text" },
        },
        tab: {
          color: "text",
          p: "16px",
          bg: "#FBFBFB",
          h: "auto",
          w: "100%",
          justifyContent: "space-between",
          "&.active": {
            bg: "primaryGradient",
            color: "white",
          },
        },
      },
    },
    Card: {
      baseStyle: {
        container: {
          boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.15)",
          rounded: "8px",
          borderColor: "transparent",
        },
      },
      variants: {
        outline: {
          container: {
            position: "relative",
            background:
              " linear-gradient(128.12deg, rgba(255, 255, 255, 0.284) 4.05%, rgba(255, 255, 255, 0) 99.78%)",
            backdropFilter: "blur(5px)",
            "::before": {
              content: '" "',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              rounded: "8px",
              border: "2px solid transparent",
              background:
                "linear-gradient(-45deg, #4026E2, #22A7FA) border-box",
              "-webkit-mask":
                "linear-gradient(#ff0000 0 0) padding-box, linear-gradient(#ff0000 0 0)",
              "-webkit-mask-composite": "xor",
            },
          },
        },
      },
    },
    List: {
      variants: {
        mobMenu: {
          container: {
            pointerEvents: "all",
            bg: "white",
            top: "0px",
            mt: "80px",
            h: "calc(100% - 80px)",
            w: "100%",
          },
          item: {
            display: "block",
            textAlign: "center",
            py: "15px",
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: "background",
        color: "text",
        fontWeight: "400",
      },
    },
  },
};

export default extendTheme(
  theme,
  withDefaultColorScheme({
    colorScheme: "blue",
  })
);
