import React from "react";
import BGImage from "assets/images/Background.jpg";
import { Box, Card, Flex, Heading, Text } from "@chakra-ui/react";
import { PORTFOLIO_UTILS } from "utils/const";

export default function PortfolioUtils() {
  return (
    <Box
      bg={` url(${BGImage}) no-repeat center`}
      bgSize="cover"
      py={{ base: "60px", md: "150px" }}
      px="15px"
    >
      <Flex justify="center" gap="32px" textAlign="center">
        {PORTFOLIO_UTILS?.map(({ label, value }) => (
          <Card
            key={label}
            p={{ base: "14px 8px", md: "38px 20px" }}
            variant="outline"
            maxW={{ base: "100px", md: "200px" }}
            minW="100px"
          >
            <Text
              mb={{ base: "5px", md: "15px" }}
              fontSize={{ base: "12px", md: "20px" }}
              color="white"
              fontWeight="600"
            >
              {label}
            </Text>
            <Heading
              fontWeight="700"
              color="white"
              fontSize={{ base: "20px", md: "39px" }}
            >
              {value}
            </Heading>
          </Card>
        ))}
      </Flex>
    </Box>
  );
}
