export default [
  {
    title: "Job Posting",
    desc: "We post open job positions on our website, job boards, and social media channels.",
  },
  {
    title: "Resume review",
    desc: "Our HR team reviews all resumes submitted for the open position and shortlists the most qualified candidates.",
  },
  {
    title: "Initial Screening",
    desc: "Shortlisted candidates are invited for an initial screening interview, which may be conducted over the phone or via video conferencing.",
  },
  {
    title: "Technical Assessment",
    desc: "Candidates who pass the initial screening are then given a technical assessment or test to evaluate their skills and knowledge in the relevant technology or programming language",
  },
  {
    title: "Technical Interview",
    desc: "Candidates who perform well in the technical assessment are invited for a technical interview with a senior member of the development team.",
  },
  {
    title: "Reference Check",
    desc: "We post open job positions on our website, job boards, and social media channels.",
  },
  {
    title: "Job Offer",
    desc: "We post open job positions on our website, job boards, and social media channels.",
  },
  {
    title: "Onboarding",
    desc: "Once the candidate accepts the job offer, they undergo an onboarding process that includes orientation, training, and introduction to the team.",
  },
];
