import {
  ConsultaionPhase,
  Deployment,
  Design,
  Development,
  Maintance,
  Requirements,
  Testing,
} from "assets/icons";

export default [
  {
    Icon: ConsultaionPhase,
    number: 1,
    heading: "Consultation phase",
    detail:
      "At start we consult our client, gather & analyze data, establish communication protocols.",
  },
  {
    Icon: Requirements,
    number: 2,
    heading: "Requirements",
    detail:
      "Create a detailed project plan that outlines the project scope, milestones, deliverables, and resources required",
  },
  {
    Icon: Design,
    number: 3,
    heading: "Design Phase",
    detail:
      "Develop wireframes, prototypes, and other design artifacts that align with the client&apso;s vision and expectations",
  },
  {
    Icon: Development,
    number: 4,
    heading: "Development Phase",
    detail:
      "Implement an agile development process that enables rapid iteration and feedback",
  },
  {
    Icon: Testing,
    number: 5,
    heading: "Testing Phase",
    detail:
      "Conduct rigorous testing and quality assurance processes to ensure the product functions as intended and meets quality standards",
  },
  {
    Icon: Deployment,
    number: 6,
    heading: "Deployment",
    detail:
      "Work with the client to ensure a successful deployment and launch of the product",
  },

  {
    Icon: Maintance,
    number: 7,
    heading: "Maintenance",
    detail:
      "Provide ongoing maintenance and support services to ensure the product continues to meet the client's needs and functions as intended",
  },
];
