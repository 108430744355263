import React from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/layout";
import Layout from "layout";
import mask from "assets/images/bubbles-bg.png";
import { Image } from "@chakra-ui/image";
import wallet from "assets/images/walletImg.png";
import { Button } from "@chakra-ui/button";
import { ListTickIcon, TickOutline } from "assets/icons";
import { Card } from "@chakra-ui/card";
import {
  BrandingFeatures,
  PaymentPlanType,
  SoftwareFeatures,
  WalletData,
} from "utils/data/blockchain.data";
import supremeUI from "assets/images/supremeUI.jpg";
import softwareFeatures from "assets/images/softwareFeatures.png";
import { intoView } from "utils/hekper";
export default function YourWalletYourChoice() {
  return (
    <Layout>
      <Box h="491px" bgGradient="linear(to-r, #22A7FA, #4026E2)" pos="relative">
        {" "}
        <Image src={mask} pos="absolute" h="100%" right="0" />
        <Image
          src={mask}
          pos="absolute"
          h="100%"
          left="0"
          transform="scaleX(-1)"
        />
        <Container maxW="1280px">
          <Flex>
            <Box flex={5.5} py="4rem">
              <Heading fontSize="61px" fontWeight="700" color="white">
                Your Wallet,
                <br />
                Your Choice
              </Heading>
              <Text
                mt="15px"
                mb="40px"
                color="white"
                fontSize="18px"
                maxW="450px"
                w="100%"
              >
                Integrating custom wallets inside your app with custom branding,
                best in class security, in less than 10 lines of code.{" "}
              </Text>
              <Button
                bg="white"
                color="#22A7FA"
                onClick={() => intoView("contact")}
              >
                TALK TO US
              </Button>
            </Box>
            <Box flex={4.5} display={{ base: "none", lg: "block" }}>
              <Image
                src={wallet}
                maxW={{ base: "none", md: "400px", lg: "500px", xl: "767px" }}
                w="100%"
                pos="absolute"
                right={{ lg: "4rem", xl: "8rem" }}
                bottom="0"
              />
            </Box>
          </Flex>
        </Container>
      </Box>
      <Container maxW="1280px" my="70px" textAlign="center">
        <Heading fontSize="39px" fontWeight="700" color="#3264EE">
          E-Wallet Apps to Scale Ambitions
        </Heading>
        <Text fontSize="20px" fontWeight="300" mt="30px">
          Blockyfy white-label digital wallet platform helps companies,
          financial institutions and blockchain create fully managed e-wallet
          payment systems and scale them over time with the most suitable and
          required features. Our mobile wallet software comes as a collection of
          configurable standalone modules to deliver unique solutions and meet
          specific business needs.
          <br />
          <br />
          Together with iOS and Android virtual wallet apps we deliver web
          interfaces and open API for endless integration opportunities. We
          handle ongoing maintenance and technical support to make our clients
          feel more confident about their business.
        </Text>
        <Flex flexWrap="wrap" gap="70px" justify="center" mt="60px">
          {WalletData?.map((data) => (
            <Box maxW="450px" textAlign="start" key={data?.title}>
              <Flex bg="#F3F6FF" borderRadius="10px" p="19px" gap="25px">
                <TickOutline />
                <Text fontSize="20px" fontWeight="700" color="#3264EE">
                  {data?.title}
                </Text>
              </Flex>
              <Text fontSize="16px" fontWeight="400" mt="20px">
                {data?.detail}
              </Text>
            </Box>
          ))}
        </Flex>
      </Container>
      <Box h="100%" bgGradient="linear(to-r, #22A7FA, #4026E2)" pos="relative">
        {" "}
        <Image src={mask} pos="absolute" bottom="0" h="450px" right="0" />
        <Image
          src={mask}
          pos="absolute"
          bottom="0"
          left="0"
          h="450px"
          transform="scaleX(-1)"
        />
        <Container maxW="1280px">
          <Image
            display={{ base: "none", lg: "block" }}
            src={softwareFeatures}
            maxW={{ base: "450px", xl: "717px" }}
            w="100%"
            pos="absolute"
            right="0"
            bottom="0"
          />
          <Box py="50px">
            <Heading
              fontSize="39px"
              fontWeight="700"
              color="white"
              mb="30px"
              textAlign={{ base: "center", md: "unset" }}
            >
              Mobile Wallet Software Features
            </Heading>
            {SoftwareFeatures?.map((item) => (
              <Flex maxW="501px" gap="20px" key={item?.heading} mb="40px">
                <Center bg="white" alignSelf="center" rounded="full" p="22px">
                  {item?.icon}
                </Center>
                <Box color="white">
                  <Heading color="white" fontSize="20px" fontWeight="700">
                    {item?.heading}
                  </Heading>
                  <Text color="white" mt="10px" fontWeight="400">
                    {item?.detail}
                  </Text>
                </Box>
              </Flex>
            ))}
          </Box>
        </Container>
      </Box>
      <Container maxW="1280px" my="70px">
        <Heading fontSize="39px" fontWeight="700" textAlign="center" mb="30px">
          Supreme UI{" "}
        </Heading>
        <Text textAlign="center">
          Perfectly crafted user interface and experiences to drive customer
          satisfaction and greater engagement. Available for iOS, Android and
          Web. Apply your branding and go live with a spectacular app in just
          weeks.
        </Text>
        <Image src={supremeUI} mt="30px" mb="20px" />
        <Heading fontSize="39px" fontWeight="700" textAlign="center" my="60px">
          Build apps with best-in-class branding features
        </Heading>
        <Flex flexWrap="wrap" gap="17px" justify="center ">
          {BrandingFeatures.map((card) => (
            <Card
              p="40px 25px"
              maxW="480px"
              w="100%"
              key={card?.title}
              boxShadow="xs"
            >
              <Image src={card?.image} maxW="max-content" />
              <Heading fontSize="24px" fontWeight="400" my="25px">
                {card?.title}
              </Heading>
              <Text>{card?.detail}</Text>
            </Card>
          ))}
        </Flex>
      </Container>
      <Box
        bg="linear-gradient(119.14deg, rgba(34, 167, 250, 0.03) 0.68%, rgba(64, 38, 226, 0.03) 99.22%);"
        py="55px"
      >
        <Heading fontSize="32px" fontWeight="600" mb="14px" textAlign="center">
          Choose your edition
        </Heading>
        <Text fontSize="16px" fontWeight="400" textAlign="center">
          Every crypto feature and functionality we offer is defined by
          real-world user preferences. Just select the very White Label crypto
          solution that best matches the needs of your business or organisation.
        </Text>
        <Container maxW="1280px" mt="55px">
          <Flex gap="14px" flexWrap="wrap" justify="center">
            {PaymentPlanType?.map((type) => (
              <Card
                maxW="300px"
                h="780px"
                boxShadow="md"
                p="40px 20px"
                transform="scale(0.97)"
                key={type?.type}
                _hover={{
                  bg: "#2E75F1",
                  transform: "scale(1)",
                  transition: "transform 0.7s ease",
                  "& .child-element": { color: "white" },
                  "& .child-btn": {
                    bg: "#0753D7",
                    color: "white",
                  },
                }}
              >
                <VStack justify="space-between" h="730px">
                  <Box>
                    <Heading
                      className="child-element"
                      fontSize="24px"
                      fontWeight="600"
                      mb="10px"
                    >
                      {type?.type}
                    </Heading>
                    <Text fontWeight={400} mb="35px" className="child-element">
                      {type?.description}
                    </Text>
                    <Heading
                      fontSize="20px"
                      fontWeight="700"
                      borderBottom="2px solid "
                      pb="20px"
                      className="child-element"
                    >
                      {type?.price}
                    </Heading>
                    <List spacing={3} mt="12px" className="child-element">
                      {type?.properties?.map((prop) => (
                        <ListItem display="flex" gap="10px" key={prop}>
                          <ListTickIcon />
                          {prop}
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Button
                    variant="ghost"
                    color="black"
                    className="child-btn"
                    onClick={() => intoView("contact")}
                  >
                    Request proposal
                  </Button>
                </VStack>
              </Card>
            ))}
          </Flex>
        </Container>
      </Box>
    </Layout>
  );
}
