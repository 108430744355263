import {
  BlockChainDevIcon,
  MobileDevIcon,
  QAIcon,
  TSIcon,
  UIDevIcon,
  WebDevIcon,
} from "assets/icons";

export default [
  {
    path: "/blockchain-dev",
    title: "Blockchain Development",
    Icon: BlockChainDevIcon,
    disc: "Our blockchain development solutions can be applied to various industries, including finance, healthcare, supply chain, real estate, and more. ",
  },
  {
    path: "/web-development",
    title: "Web Development",
    Icon: WebDevIcon,
    disc: "Our web development services can help businesses establish an online presence, improve their website's functionality, and create custom web solutions that meet their unique needs.",
  },
  {
    path: "/app-development",
    title: "Mobile App Development",
    Icon: MobileDevIcon,
    disc: "We offer mobile development services for both businesses and individuals who want to develop mobile applications.",
  },
  {
    path: "/ui-ux-design-service",
    title: "UI/UX & Graphic Designing",
    Icon: UIDevIcon,
    disc: "Our UI/UX and graphic design services work to create a seamless and enjoyable user experience for digital products, helping businesses to stand out and succeed in today's competitive online market.",
  },
  {
    path: "/qa-engineering",
    title: "Quality Assurance",
    Icon: QAIcon,
    disc: "QA is an essential part of the software development process, in which we aim to ensure that the software product meets the required quality standards before it is released to the market.",
  },
  {
    path: "/your-wallet-your-choice",
    title: "Wallet",
    Icon: TSIcon,
    disc: "Our mobile wallet software comes as a collection of configurable standalone modules to deliver unique solutions and meet specific business needs.",
  },
];
