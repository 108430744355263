import EarnXCover from "assets/images/earnX-cover.jpg";
import earnX from "assets/images/earnX-img.jpg";
import fanSphere from "assets/images/fanSphere.jpg";
import fanSphereCover from "assets/images/fanSphare-cover.jpg";
import tagoPay from "assets/images/tagoPay.jpg";
import tagoPayCover from "assets/images/tagoPay-cover.jpg";
import trenergo from "assets/images/trenergo.jpg";
import trenergoCover from "assets/images/trenergo-cover.png";
import metasuite from "assets/images/metasuite.jpg";
import metasuiteCover from "assets/images/metaSuite-cover.jpg";

export default [
  {
    filter: ["all", "web", "blockchain"],
    type: "Web Application",
    name: "EarnX",
    link: "https://earnxnft.io",
    coverImg: EarnXCover,
    thumbnail: earnX,
    disc: "While most NFT projects hope to increase in value through popularity, EarnX NFT derives its value from actual assets chosen by savvy and experienced analysts. EarnX is the most solvent NFT project ever introduced, allowing any holder to cash out at any time.",
  },
  {
    filter: ["all", "design"],

    type: "Web Application",
    name: "Fansphere",
    link: "",
    coverImg: fanSphereCover,
    thumbnail: fanSphere,
    disc: "The world’s first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.",
  },
  {
    filter: ["all", "web"],

    type: "Web Application",
    name: "TagoPay",
    link: "https://tagopay.com/",
    coverImg: tagoPayCover,
    thumbnail: tagoPay,
    disc: "TagoPay, Inc. is an innovative digital asset transfer company built on the powerful idea of making it as convenient and affordable as possible to pay anyone.",
  },
  {
    filter: ["all", "web"],

    type: "Web Application",
    name: "Trenergo",
    link: "https://studio.trenergo.com/",
    coverImg: trenergoCover,
    thumbnail: trenergo,
    disc: "Create professional virtual classes and on-demand workouts without spending hours in postproduction",
  },
  {
    filter: ["all", "mobile", "blockchain"],

    type: "Mobile Application",
    name: "Metasuite City",
    link: "https://apps.apple.com/us/app/metasuite-city/id6445837762",
    coverImg: metasuiteCover,
    thumbnail: metasuite,
    disc: "While	most	NFT	projects	hope	to	increase	in	value	through	popularity,	EarnX	NFT	derives	its	value	from	actual	assets	chosen	by	savvy	and	experienced	analysts.",
  },
];
