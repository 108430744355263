import React from "react";
import { Box, Center, Heading, Text } from "@chakra-ui/react";

export default function RoadMapCard({
  icon,
  number,
  heading,
  detail,
}: {
  icon: JSX.Element;
  number: number;
  heading: string;
  detail: string;
}) {
  return (
    <Box maxW="250px" w="100%" textAlign="center">
      <Center
        boxSize="100px"
        rounded="full"
        border="2px solid #22A7FA"
        mx="auto"
        mb="1rem"
        bg="white"
      >
        {icon}
      </Center>
      <Text fontSize="20px" fontWeight="600">
        {number}
      </Text>
      <Heading fontSize="20px" fontWeight="600" my="8px">
        {heading}
      </Heading>
      <Text fontSize="12px" fontWeight="400">
        {detail}
      </Text>
    </Box>
  );
}
