import {
  DailyLunch,
  EasyEnvironment,
  EmplyeeStock,
  InsuranceIcon,
  PaidLeaves,
  PersonalDevelopment,
} from "assets/icons";

export default [
  {
    icon: InsuranceIcon(),
    heading: "Health Insurance",
    desc: "We prioritize the well-being of our employees by providing comprehensive health insurance coverage, ensuring they have access to quality healthcare and peace of mind.",
  },
  {
    icon: EasyEnvironment(),
    heading: "Easy Work Environment",
    desc: "Our company fosters a supportive and inclusive work environment, promoting open communication, work-life balance, and flexibility to enhance productivity and employee satisfaction.",
  },
  {
    icon: PersonalDevelopment(),
    heading: "Personal Development",
    desc: "We invest in our employees growth and development. Through training programs, workshops, and mentorship, we empower individuals to reach their full potential and achieve professional excellence.",
  },
  {
    icon: EmplyeeStock(),
    heading: "Employee Stock Options",
    desc: "We care about our employees financial well-being. Our company offers an employee fund to support their long-term goals, providing opportunities for savings, investments, or other financial needs.",
  },
  {
    icon: DailyLunch(),
    heading: "Daily Office Lunch",
    desc: "Enjoy a delicious and nutritious meal on us! We believe in fueling our employees success by providing daily office lunches, creating a convenient and enjoyable dining experience.",
  },
  {
    icon: PaidLeaves(),
    heading: "Paid Leaves",
    desc: "We understand the importance of work-life balance. Employees receive paid leaves, enabling them to take time off for vacations, personal commitments, or simply to recharge and maintain a healthy work-life integration.",
  },
];
