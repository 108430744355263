import React from "react";
import { Box, Center, Container, Flex, Heading, Text } from "@chakra-ui/layout";
import Layout from "layout";
import semicircle from "assets/images/semicircle.png";
import { Button, Card, Image } from "@chakra-ui/react";
import { OurQAProcess, TypesOfTesting, WhyBlockyfy } from "utils/data/qa.data";
import qaheader from "assets/images/qaheader.jpg";
import blockyfyVector from "assets/images/blockyfyVector.png";
import { intoView } from "utils/hekper";

export default function QAEngineering() {
  return (
    <Layout>
      <>
        <Box
          bg={`url(${qaheader}) no-repeat center`}
          bgSize="100% 100%"
          h="512px"
          textAlign="center"
        >
          <Flex
            flexDir="column"
            gap="15px"
            justify="center"
            align="center"
            maxH="512px"
            h="100%"
          >
            <Heading
              fontSize={{ base: "26px", md: "58px" }}
              fontWeight="900"
              color="white"
            >
              QA Engineering And
              <br /> Software Testing Services
            </Heading>
            <Text
              fontSize={{ base: "16px", md: "18px" }}
              fontWeight="400"
              color="white"
              maxW="722px"
              w="100%"
            >
              We are specialized in creating beautiful and smooth UI/UX designs
              that provide better user experience by incorporating effective
              collaboration, streamlined projects which strive for better
              results.
            </Text>
            <Button mt="35px" onClick={() => intoView("contact")}>
              TALK TO US
            </Button>
          </Flex>
        </Box>
        <Container maxW="1280px" my="70px" pos="relative">
          <Heading fontWeight={700} fontSize="39px" textAlign="center">
            Types of Testing That We Do
          </Heading>
          <Image src={semicircle} alt="semi" position="absolute" />
          <Flex
            flexWrap="wrap"
            gap={{ base: "2rem", lg: "81px" }}
            mt="50px"
            justify="space-around"
          >
            {TypesOfTesting?.map((type) => (
              <Card
                maxW="350px"
                w="100%"
                key={type?.name}
                p="20px"
                boxShadow="xs"
              >
                <Heading color="#3168EE" fontWeight={700} fontSize="22px">
                  {type?.name}
                </Heading>
                <Text mt="16px" fontWeight={400}>
                  {type?.description}
                </Text>
              </Card>
            ))}
          </Flex>
        </Container>
        <Box
          h="100%"
          bgGradient="linear(to-r, #22A7FA, #4026E2)"
          pos="relative"
        >
          <Image
            src={blockyfyVector}
            pos="absolute"
            h="450px"
            left="5%"
            top="20%"
          />
          <Container maxW="1280px" py="50px">
            <Heading
              fontWeight={700}
              fontSize="39px"
              textAlign="center"
              color="white"
            >
              Why Blockyfy?
            </Heading>
            <Flex flexWrap="wrap" gap="58px" mt="50px" justify="center">
              {WhyBlockyfy?.map((offer) => (
                <Box
                  textAlign="center"
                  key={offer?.title}
                  maxW="285px"
                  w="100%"
                >
                  <Center>{offer?.image}</Center>
                  <Heading
                    color="white"
                    fontWeight={700}
                    fontSize="23px"
                    mt="16px"
                  >
                    {offer?.title}
                  </Heading>
                  <Text mt="5px" color="white" fontWeight={400} fontSize="16px">
                    {offer?.detail}
                  </Text>
                </Box>
              ))}
            </Flex>
          </Container>
        </Box>
        <Container maxW="1280px" mt="50px" mb="90px">
          <Heading
            fontWeight={700}
            fontSize="39px"
            textAlign="center"
            mb="50px"
          >
            Our QA Process
          </Heading>
          {OurQAProcess?.map((process, index) => (
            <Flex gap="36px" key={index}>
              <Heading color="#3F2EE4" fontWeight={700} fontSize="76px">
                0{index + 1}
              </Heading>
              <Box borderBottom="1px solid black" pb="39px" mb="28px">
                <Heading fontWeight={700} fontSize="22px">
                  {process?.name}
                </Heading>
                <Text fontSize="17px" fontWeight={400} mt="2rem">
                  {process?.description}
                </Text>
              </Box>
            </Flex>
          ))}
        </Container>
      </>
    </Layout>
  );
}
