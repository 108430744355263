import {
  CrossPlatform,
  Hifi,
  PrototypeIcon,
  SoftwareRedesign,
  TechinalDesign,
  WebAndMobile,
} from "assets/icons";

export default [
  {
    Icon: PrototypeIcon,
    title: "High-fidelity Prototype",
    disc: `We create a prototype to demonstrate the behavior of the
        application and show how it would work in reality. We design
        fully-interactive prototypes to give you an idea of the workflow
        of your project.`,
  },
  {
    Icon: Hifi,
    title: "Hi-Fi and Lo-Fi Wireframes",
    disc: `We design wireframes to provide you an outline of a mobile or
        web app by demonstrating content, structure and functionality
        that will exist on your app’s and software’s pages.`,
  },
  {
    Icon: WebAndMobile,
    title: "Mobile and Web User Interface Design",
    disc: `We deliver our best efforts to ingeniously convert your ideas and
        thoughts into a clear and fully functional design. We design user
        interfaces that conform to what the user requires and
        understands.`,
  },
  {
    Icon: CrossPlatform,
    title: "Cross-Platform Compatibility",
    disc: `Focusing on the core principles of UI design and functionality
        implementation, we design and deliver responsive and cross-
        platform compatible designs to ensure that it works across
        different platforms.`,
  },
  {
    Icon: TechinalDesign,
    title: "Technical Design",
    disc: `Our team can also create a technical design that helps our
        clients understand the technical workflow of the project. In
        technical design, we define technology stack for all the
        components of the app, third-party integrations and interactions
        of services and APIs.`,
  },
  {
    Icon: SoftwareRedesign,
    title: "Software Redesign",
    disc: `If you already have an app or a website but it does not look
        appealing or professional, we can help you by re-designing the
        entire website or application. After evaluating your app and
        understanding the users, we make your software look engaging
        and beautiful.`,
  },
];
