import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
  createStandaloneToast,
} from "@chakra-ui/react";
import { BlockyFyLogo } from "assets/icons";
import ContactUsBg from "assets/images/contact-bg.jpg";
import CirclesBg from "assets/images/circles-bg.jpg";
// import Mailjet from "node-mailjet";
import { useForm } from "react-hook-form";
import { ContactUsFormType } from "utils/types/global.type";

export default function ContactUs() {
  return (
    <Box>
      <Box
        as="section"
        bg={`url(${ContactUsBg}) no-repeat center`}
        bgSize="cover"
        pt="140px"
        pb="390px"
        position="relative"
      >
        <Box
          bg="#101010"
          mixBlendMode="saturation"
          position="absolute"
          w="100%"
          h="100%"
          top="0"
        ></Box>
        <Box
          bg="primaryGradient"
          mixBlendMode="multiply"
          position="absolute"
          w="100%"
          h="100%"
          top="0"
        ></Box>
        <Container
          textAlign="center"
          maxW="850px"
          zIndex={1}
          position="relative"
        >
          <Heading as="h6" color="white" variant="icon">
            CONTACT US
          </Heading>
          <Heading
            as="h2"
            variant="primary"
            fontSize={{ base: "32px", md: "40px" }}
            textTransform="uppercase"
          >
            Get in touch with us and {`let's`} make technology work for you.
          </Heading>
        </Container>
      </Box>
      <Card
        flexDirection={{ base: "column", lg: "row" }}
        variant="outline"
        mx={{ base: "15px", lg: "auto" }}
        mt="-300px"
        maxW="1000px"
        mb="120px"
        minH="600px"
        position="relative"
        zIndex="2"
        p={{ base: "25px", lg: "70px" }}
        gap="45px"
        id="contact"
      >
        <Box
          bg={`url(${CirclesBg}) no-repeat top right`}
          bgSize="cover"
          rounded="8px"
          textAlign="center"
          p="50px"
          maxW={{ lg: "320px" }}
          fontSize="13px"
        >
          <BlockyFyLogo />
          <Text variant="primary" mt="20px">
            Email/Phone
          </Text>
          <Divider my="10px" />
          <Text variant="primary">
            info@blockyfy.com
            <br />
            +92 (344) - 6199334
          </Text>
          <Divider borderStyle="dashed" borderColor="blue.900" mt="20px" />
          <Text variant="primary" mt="20px">
            Office Address
          </Text>
          <Divider my="10px" />
          <Text variant="primary">
            Luxus Mall Gulberg Greens, Islamabad, Pakistan.
          </Text>
          <Divider borderStyle="dashed" borderColor="blue.900" mt="20px" />
          <Text variant="primary" mt="20px">
            Office Timings
          </Text>
          <Divider my="10px" />
          <Text variant="primary">
            09:00 AM to 06:00 PM
            <br />
            Mon - Fri
          </Text>
        </Box>
        <ContactUsForm />
      </Card>
    </Box>
  );
}

const ContactUsForm = () => {
  const { toast } = createStandaloneToast();
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ContactUsFormType>();

  const onSubmit = (data: ContactUsFormType) => {
    setLoading(true);
    fetch("https://api.blockyfy.com/email/blockyfy-contact-us-email", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        reset();
        setLoading(false);
        if (result?.error)
          return toast({
            status: "error",
            description: result?.error,
            position: "top-right",
            isClosable: true,
          });

        toast({
          status: "success",
          description: result?.message,
          position: "top-right",
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          status: "error",
          description: err?.message,
          position: "top-right",
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      as="form"
      wrap="wrap"
      gap="12px"
      maxW={{ lg: "495px" }}
      w="100%"
      alignContent="flex-start"
      onSubmit={handleSubmit(onSubmit)}
      direction={{ base: "column", md: "row" }}
    >
      <FormControl
        w={{ md: "calc(50% - 6px)" }}
        border="1px solid"
        borderColor="borderColor"
        rounded="8px"
        p="16px 16px 10px"
        bg="white"
        isInvalid={Boolean(errors?.name)}
      >
        <FormLabel fontSize="13px" m="0">
          Your Name
        </FormLabel>
        <Input
          border="none"
          type="text"
          focusBorderColor="transparent"
          p="0"
          placeholder="Albert Marck"
          {...register("name", { required: "Name is required" })}
          boxShadow="none !important"
        />
        <FormErrorMessage>
          {errors?.name && errors?.name?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        w={{ md: "calc(50% - 6px)" }}
        border="1px solid"
        borderColor="borderColor"
        rounded="8px"
        p="16px 16px 10px"
        bg="white"
        isInvalid={Boolean(errors?.phone)}
      >
        <FormLabel fontSize="13px" m="0">
          Your Phone Number
        </FormLabel>
        <Input
          border="none"
          type="number"
          focusBorderColor="transparent"
          p="0"
          placeholder="000 (000) - 356789"
          {...register("phone", { required: "Number is required" })}
          boxShadow="none !important"
        />
        <FormErrorMessage>
          {errors?.phone && errors?.phone?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        w="100%"
        border="1px solid"
        borderColor="borderColor"
        rounded="8px"
        p="16px 16px 10px"
        bg="white"
        isInvalid={Boolean(errors?.email)}
      >
        <FormLabel fontSize="13px" m="0">
          Your Email
        </FormLabel>
        <Input
          border="none"
          type="email"
          focusBorderColor="transparent"
          boxShadow="none !important"
          p="0"
          placeholder="user@email.com"
          {...register("email", {
            required: "Email is required",
            validate: {
              validateEmail: (email) => {
                return (
                  String(email)
                    .toLowerCase()
                    .match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ) || "Invalid Email"
                );
              },
            },
          })}
        />
        <FormErrorMessage>
          {errors?.email && errors?.email?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        w="100%"
        border="1px solid"
        borderColor="borderColor"
        rounded="8px"
        p="16px 16px 10px"
        bg="white"
        isInvalid={Boolean(errors?.message)}
      >
        <FormLabel fontSize="13px" m="0">
          Type a Message
        </FormLabel>
        <Textarea
          mt="15px"
          minH="130px"
          resize="none"
          border="none"
          boxShadow="none !important"
          focusBorderColor="transparent"
          p="0"
          placeholder="Message..."
          {...register("message", { required: "Message is required" })}
        />
        <FormErrorMessage>
          {errors?.message && errors?.message?.message}
        </FormErrorMessage>
      </FormControl>
      <Button mt="30px" isLoading={isLoading} w="100%" type="submit">
        Submit
      </Button>
    </Flex>
  );
};
