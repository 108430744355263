import React from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/layout";
import Layout from "layout";
import AppDev from "assets/images/appDev.jpg";
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import Dots from "assets/images/dots.png";
import crossPlatform from "assets/images/crossPlatform.jpg";
import servicesBgAppDev from "assets/images/servicesBgAppDev.jpg";
import { AppDevelopmentServices } from "utils/data/blockchain.data";
import { useNavigate } from "react-router-dom";
export default function AppDevelopment() {
  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  };
  return (
    <Layout hideContact>
      <Box
        bg={`url(${AppDev}) no-repeat center`}
        bgSize="100% 100%"
        h="512px"
        textAlign="center"
      >
        <Flex
          flexDir="column"
          gap="15px"
          justify="center"
          align="center"
          maxH="512px"
          h="100%"
        >
          <Heading fontSize="58px" fontWeight="900" color="white">
            App Development
          </Heading>
          <Text
            fontSize="18px"
            fontWeight="400"
            color="white"
            maxW="722px"
            w="100%"
          >
            We help global brands design and build superior digital products,
            enabling seamless user experiences across all modern platforms and
            devices.
          </Text>
          <Button mt="35px" onClick={navigateToContact}>
            TALK TO US
          </Button>
        </Flex>
      </Box>
      <Container maxW="1280px" textAlign="center" mt="97px">
        <Heading fontSize="31px" fontWeight="700">
          OUR EXPERTISE WITH MAJOR MOBILE APP
          <br /> DEVELOPMENT TECHNOLOGIES
        </Heading>
        <Text fontWeight="400" mt="16px">
          Delight your users with expressive and feature-rich native iOS /
          Android, cross-platform
          <br /> or Progressive Web Apps leveraging our extensive experience of
          working with all major
          <br /> technologies.
        </Text>
      </Container>
      <Box pos="relative" py="2rem">
        <Box pos="absolute" zIndex={-1}>
          <Image src={Dots} />
        </Box>
        <Container
          maxW="1280px"
          boxShadow="base"
          p="36px 40px"
          my="60px"
          borderRadius="7px"
          bg="white"
        >
          <Flex flexDir={{ base: "column", md: "row" }}>
            <Box flex={0.6}>
              <Heading fontSize="25px" fontWeight={700}>
                Cross-platform App Development
              </Heading>
              <Text mt="21px">
                Get top-notch mobile applications that run on multiple platforms
                costs while providing your users with an app that fits their OS.
                Use this alternative to reduce costs and time-to-market and to
                reach more users without loss of quality, including:
              </Text>
              <UnorderedList mt="49px" spacing="10px">
                <ListItem>Hybrid Mobile App Development</ListItem>
                <ListItem>Hybrid Tablet App Development</ListItem>
                <ListItem>Hybrid TV App Development</ListItem>
              </UnorderedList>
            </Box>
            <Box flex={0.4}>
              <Image src={crossPlatform} alt="img" mx="auto" />
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box
        bg={`url(${servicesBgAppDev}) no-repeat center`}
        bgSize="100% 100%"
        h="100%"
        textAlign="center"
      >
        <Container maxW="1280px" py="104px">
          <Heading fontSize="32px" fontWeight={600} color="white">
            MOBILE APPLICATION DEVELOPMENT <br />
            SERVICES FOR VARIOUS PLATFORMS
          </Heading>
          <Text
            fontSize="18px"
            fontWeight="400"
            color="white"
            mt="25px"
            mb="54px"
          >
            Our mobile app developers have over a decade-long expertise in
            building solutions <br />
            that meet market needs, empower companies’ brand identity, and
            encourage business <br />
            growth and expansion.
          </Text>
          <Flex
            justify="center"
            gap={{ base: "1.9rem", md: "1rem" }}
            flexWrap="wrap"
          >
            {AppDevelopmentServices?.map((service) => (
              <Box maxW="400px" key={service?.heading}>
                <Center>{service?.image}</Center>
                <Heading
                  color="white"
                  fontSize="18px"
                  fontWeight="700"
                  my="1rem"
                >
                  {service?.heading}
                </Heading>
                <Text color="white" fontSize="16px" fontWeight="400">
                  {service?.description}
                </Text>
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>
    </Layout>
  );
}
