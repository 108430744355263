import React from "react";
import {
  Box,
  Container,
  Divider,
  Flex,
  List,
  ListItem,
  Text,
  Image,
} from "@chakra-ui/react";
import logo from "assets/images/logo.svg";
import { COMPANY_MENU, OTHERS_MENU, SERVICES_MENU } from "utils/const";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "assets/icons";
import ContactUs from "components/contactUs";
import { intoView } from "utils/hekper";

export default function Footer({ hideContact }: { hideContact?: boolean }) {
  return (
    <>
      {!hideContact && <ContactUs />}
      <Box py="60px" as="footer" bg="primaryGradient" p="80px 0 40px">
        <Container maxW="1280px">
          <Flex justify="space-between">
            <Box w="100%" maxW="400px">
              <Image src={logo} />
              <Text variant="primary" my="30px" maxW="300px" fontSize="13px">
                We offer various IT services to help our clients stay ahead in
                the constantly evolving technology landscape and achieve their
                goals.
              </Text>
              <Flex gap="20px" mb="30px">
                <a
                  href="https://pk.linkedin.com/company/blocky-fy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedinIcon />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100093613092186"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.instagram.com/blockyfy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
              </Flex>
            </Box>
            <FooterLinks title="Company" menu={COMPANY_MENU} />
            <FooterLinks title="Services" menu={SERVICES_MENU} />
            <FooterLinks title="Others" menu={OTHERS_MENU} />
          </Flex>
          <Divider
            borderColor="white"
            my="30px"
            display={{ base: "none", md: "block" }}
          />
          <Flex justify="space-between">
            <Text variant="primary">© 2023 Blockyfy. All rights reserved.</Text>
            <Text variant="primary" display={{ base: "none", md: "block" }}>
              Created with love by Blockyfy.
            </Text>
          </Flex>
        </Container>
      </Box>
    </>
  );
}

function FooterLinks({
  title,
  menu,
}: {
  title: string;
  menu: { label: string; path?: string; id?: string }[];
}) {
  return (
    <List
      spacing="15px"
      w="100%"
      maxW="250px"
      display={{ base: "none", md: "block" }}
    >
      <ListItem color="white" fontWeight="600">
        {title}
      </ListItem>
      {menu.map(({ label, path, id }) => (
        <ListItem
          as={Link}
          to={path}
          key={label}
          display="block"
          color="white"
          fontSize="13px"
          onClick={() => id && intoView(id)}
        >
          {label}
        </ListItem>
      ))}
    </List>
  );
}
